import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, forEach, isArray, isObject, isString, keys, some, uniq } from 'lodash-es';
import { Promise } from 'bluebird';
import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';

import { Tour } from '../interfaces/Interfaces';
import { removeItemFromStorage, setItemToStorage } from '../helpers/storage-helpers';
import useFileSystem from './useFileSystem';
import useDownloadStore from '../stores/useDownloadStore';
import useToast from './useToast';
import { useTourByTourIdQuery } from '../graphql/dato/__generated__/dato-graphql.generated';
import useAuthStore from '../stores/useAuthStore';
import { useLocale } from '../contexts/LocaleContext';
import { checkIfAllAudioFilesExist } from '../helpers/tour-helpers';
import { useTransaction } from '../contexts/TransactionContext';
import {
  TourAudioGenerationRequestStatus,
  useGenerateTourAudioMutation
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useError from './useError';

const keysWithMediaUrlForTour = [
  { key: 'src', parentKey: 'responsiveImage' },
  { key: 'url', parentKey: 'slideAudio' },
  { key: 'url', parentKey: 'audio' },
  { key: 'url', parentKey: 'audioPreview' },
  { key: 'url', parentKey: 'videoFile' },
  { key: 'url', parentKey: 'backgroundImage' },
  { key: 'url', parentKey: 'textTrack' },
];

const useDownload = () => {
  const { writeFile, deleteFile, getUri } = useFileSystem();
  const { presentToast } = useToast();
  const { queryLocale } = useLocale();
  const { hasPremiumAccess } = useTransaction();
  const { handleBackendError } = useError();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const user = useAuthStore((state) => state.me);
  const setDownloadingEntityId = useDownloadStore((state) => state.setDownloadingEntityId);
  const setDownloadedEntityId = useDownloadStore((state) => state.setDownloadedEntityId);
  const increaseProgress = useDownloadStore((state) => state.increaseProgress);
  const deleteDownloadedEntityId = useDownloadStore((state) => state.deleteDownloadedEntityId);

  const [pauseTourReceiving, setPauseTourReceiving] = useState<boolean>(true);
  const [tourId, setTourId] = useState<string>();

  const [generateTourAudioMutation] = useGenerateTourAudioMutation();
  const [result] = useTourByTourIdQuery({
    variables: { tourId: tourId!, locale: queryLocale },
    requestPolicy: 'network-only',
    pause: pauseTourReceiving,
  });
  const tour = result?.data?.tour as Tour;

  useEffect(() => {
    setPauseTourReceiving(true);

    const downloadMediaAndSetTourToStorage = async () => {
      if (tour) {
        const selectedVoiceId = user?.profile?.datoVoiceId;
        const allAudioFilesExist = checkIfAllAudioFilesExist(tour, hasPremiumAccess, selectedVoiceId);

        if (allAudioFilesExist) {
          const { numberOfTourMediaFiles } = await transformTour(tour as Tour, 'countMedia');
          setDownloadingEntityId(tour.id, numberOfTourMediaFiles);

          const { transformedTour } = await transformTour(tour as Tour, 'downloadMediaAndSaveRelativePath');

          await setItemToStorage(
            `tour-${transformedTour?.id}-${transformedTour?.slug}`,
            transformedTour,
          );

          setDownloadedEntityId(tour.id);
          presentToast(`downloads.downloadTour.success`);
        } else {
          setDownloadingEntityId(tour.id, 1);

          await handleBackendError(async () => {
            const { data, errors } = await generateTourAudioMutation({
              variables: { input: { datoTourId: tour.id }},
            });
            if (errors) return errors;

            const tourAudioGenerationRequest = data?.tour?.generateTourAudio;
            if (tourAudioGenerationRequest) {
              switch (tourAudioGenerationRequest.status) {
                case TourAudioGenerationRequestStatus.InProgress:
                  presentToast(`downloads.downloadTour.preparingAudio`);
                  break;
                case TourAudioGenerationRequestStatus.Failure:
                  presentToast(`downloads.downloadTour.error`,'danger');
                  break;
                case TourAudioGenerationRequestStatus.Success:
                  downloadTour(tour.id);
                  break;
              }
            }
          })
        }
      }
    };

    downloadMediaAndSetTourToStorage();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tour]
  );

  const checkIfKeyContainsMediaUrl = (
    keysWithMediaUrl: Array<{ [key: string]: string; }>,
    key: string,
    parentKey?: string,
  ) => {
    return some(keysWithMediaUrl, (keyWithMediaUrl) =>
      keyWithMediaUrl.key === key && keyWithMediaUrl.parentKey === parentKey
    );
  };

  const getFileNameFormUrl = (url: string): string => {
    return url?.split('#')?.shift()?.split('?')?.shift()?.split('/').pop() || uuidv4();
  };

  const transformTour = async (
    tour: Tour,
    transformationType: 'countMedia' | 'downloadMediaAndSaveRelativePath' | 'transformRelativePathToSrc'
  ) => {
    // don't clone object if only counting of media files is needed
    const clonedTour = transformationType === 'countMedia' ? tour : cloneDeep(tour);
    let numberOfTourMediaFiles = 0;

    const iterate = (obj: any, parentKey?: string) => {
      return Promise.each(keys(obj), async (key) => {
        switch (true) {
          case isString(obj[key]) && checkIfKeyContainsMediaUrl(keysWithMediaUrlForTour, key, parentKey):
            switch (transformationType) {
              case 'countMedia':
                numberOfTourMediaFiles += 1;
                break;
              case 'downloadMediaAndSaveRelativePath':
                await writeFile(obj[key], `tours/${tour.id}/${getFileNameFormUrl(obj[key])}`);
                obj[key] = `tours/${tour.id}/${getFileNameFormUrl(obj[key])}`;
                increaseProgress(clonedTour.id);
                break;
              case 'transformRelativePathToSrc':
                const uri = await getUri(obj[key]);
                const fileSrc = Capacitor.convertFileSrc(uri || '');
                obj[key] = fileSrc;
                break;
            }
            break;

          case isArray(obj[key]):
            await Promise.each(obj[key], async (value) => {
              // check if the value is object and iterate
              if (isObject(value)) {
                await iterate(value, key);
              }
              return;
            });
            break;

          case isObject(obj[key]):
            await iterate(obj[key], key);
            break;
        }

        return;
      });
    };

    await iterate(clonedTour);

    return {
      transformedTour: clonedTour,
      numberOfTourMediaFiles,
    };
  };

  const removeTourMedia = (tour: Tour) => {
    // clone tour object and remove media from file system in background mode
    const clonedTour = cloneDeep(tour);
    const mediaPaths: string[] = [];

    const iterate = (obj: any, parentKey?: string) => {
      return forEach(keys(obj), (key) => {
        switch (true) {
          case isString(obj[key]) && checkIfKeyContainsMediaUrl(keysWithMediaUrlForTour, key, parentKey):
            const devicePath = obj[key].slice(obj[key].indexOf('tours'), obj[key].length);
            mediaPaths.push(devicePath);
            break;

          case isArray(obj[key]):
            forEach(obj[key], (value) => {
              // check if the value is object and iterate
              if (isObject(value)) iterate(value, key);
            });
            break;

          case isObject(obj[key]):
            iterate(obj[key], key);
            break;
        }

        return;
      });
    };
    iterate(clonedTour);

    const uniqMediaPaths = uniq(mediaPaths);
    Promise.each(uniqMediaPaths, (mediaPath) => deleteFile(mediaPath));
  };

  const downloadTour = async (currentTourId: string) => {
    if (isAuthenticated && currentTourId) {
      setTourId(currentTourId);
      // perform receiving and saving tour by setting pauseTourReceiving to false
      setPauseTourReceiving(false);
    }
  };

  const removeTourFromDownloads = async (tour: Tour) => {
    removeTourMedia(tour);
    await removeItemFromStorage(`tour-${tour?.id}-${tour?.slug}`);
    deleteDownloadedEntityId(tour?.id);
  };

  return { downloadTour, removeTourFromDownloads, transformTour };
};

export default useDownload;
