import { MutableRefObject } from 'react';
import * as moment from 'moment/moment';
import { find } from 'lodash-es';

import { GroupSharing, User } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { StorySlide } from '../interfaces/Interfaces';

const playAudioByTimer = (
  isGroupSharingStart: string | null | undefined,
  myGroupSharing: GroupSharing & { isGroupSharingStart?: boolean } | null,
  currentAudioRef: MutableRefObject<HTMLAudioElement | null>,
  play: () => void,
  playbackRate: number,
  setPlaybackRate: (playbackRate: number) => void,
) => {
  return setTimeout(() => {
    // play audio if the user is not in group sharing
    // or received group sharing from subscription (!isGroupSharingStart)
    // or group sharing has isPlaying=true
    if (!isGroupSharingStart || myGroupSharing?.isPlaying) {
      // Play the audio
      play();
    }

    // Set the playback rate
    if (currentAudioRef.current) {
      currentAudioRef.current.playbackRate = playbackRate;

      // update media player values if user received group sharing not from subscription
      if (isGroupSharingStart) {
        if (myGroupSharing?.playbackRate) {
          setPlaybackRate(myGroupSharing?.playbackRate);
        }

        if (myGroupSharing?.startAudioTime) {
          let currentAudioTime;

          if (myGroupSharing?.isPlaying) {
            currentAudioTime = moment().diff(moment(myGroupSharing?.startAudioTime), 'seconds');
          } else {
            const start = moment().diff(moment(myGroupSharing?.startAudioTime), 'seconds');
            const stop = moment().diff(moment(myGroupSharing?.stopAudioTime), 'seconds');
            currentAudioTime = start - stop;
          }

          currentAudioRef.current.currentTime = currentAudioTime;
        }
      }
    }
  }, 300);
}

const getActiveAudioSrc = (
  currentStorySlide: StorySlide,
  isInternetConnection: boolean,
  hasPremiumAccess: boolean,
  user?: User | null,
) => {
  const selectedVoiceId = user?.profile?.datoVoiceId;
  const currentVoiceAudio = find(currentStorySlide?.slideVoiceAudios, ['voice.id', selectedVoiceId]);

  switch (true) {
    case (!selectedVoiceId || !hasPremiumAccess || currentStorySlide.isSlideAudioFixed || (!isInternetConnection && !currentVoiceAudio)) && !!currentStorySlide.slideAudio?.url:
      return currentStorySlide.slideAudio?.url!;
    case !!currentVoiceAudio && hasPremiumAccess:
      return currentVoiceAudio?.audio?.url!;
    case (!!user?.token):
      return `${process.env.REACT_APP_BACKEND_API_URL}/story-slide/generate-audio?datoStorySlideId=${currentStorySlide.id}&authorization=${user?.token}`;
    default:
      return `${process.env.REACT_APP_BACKEND_API_URL}/story-slide/generate-audio?datoStorySlideId=${currentStorySlide.id}`;
  }
}

export { playAudioByTimer, getActiveAudioSrc };
