import { createContext, useContext, useEffect } from 'react';

import useAuthStore from '../stores/useAuthStore';
import {
  TourAudioGenerationRequestStatus,
  useTourAudioGenerationRequestUpdatedSubscription
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useToast from '../hooks/useToast';
import useDownload from '../hooks/useDownload';

const useValue = () => {
  const { presentToast } = useToast();
  const { downloadTour } = useDownload();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const { data: tourAudioGenerationRequestUpdatedData } = useTourAudioGenerationRequestUpdatedSubscription({
    // subscribe when user is authenticated
    skip: !isAuthenticated,
  });

  useEffect(() => {
    const tourAudioGenerationRequestUpdated = tourAudioGenerationRequestUpdatedData?.tourAudioGenerationRequestUpdated;
    const tourId = tourAudioGenerationRequestUpdated?.datoTourId;
    const tourAudioGenerationRequestStatus = tourAudioGenerationRequestUpdated?.status;

    switch (tourAudioGenerationRequestStatus) {
      case TourAudioGenerationRequestStatus.Success:
        if (tourId) downloadTour(tourId);
        break;
      case TourAudioGenerationRequestStatus.Failure:
        presentToast('An error occurred. Please try again.', 'danger');
        break;
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tourAudioGenerationRequestUpdatedData]
  );

  return {};
};

const TourAudioGenerationRequestContext = createContext({} as ReturnType<typeof useValue>);

const TourAudioGenerationRequestProvider: React.FC = ({ children }) => {
  return (
    <TourAudioGenerationRequestContext.Provider value={useValue()}>
      {children}
    </TourAudioGenerationRequestContext.Provider>
  );
};

const useTourAudioGenerationRequest = () => {
  return useContext(TourAudioGenerationRequestContext);
};

export { TourAudioGenerationRequestProvider, useTourAudioGenerationRequest };
