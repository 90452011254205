import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAudio, usePrevious } from 'react-use';
import * as moment from 'moment';
import { inRange, isEqual } from 'lodash-es';
import { DeepPartial } from 'react-hook-form';
import { isPlatform } from '@ionic/react';
import Hls from 'hls.js';
import axios from 'axios';

import useMediaPlayerStore, { initialPlaybackState } from '../stores/useMediaPlayerStore';
import { Story, StorySlide, Tour } from '../interfaces/Interfaces';
import useStoryPlayer from '../hooks/useStoryPlayer';
import { MixpanelEvents, MixpanelPeopleProperties, useMixpanel } from './MixpanelContext';
import {
  CreateUserStorySlideAudioProgressInput,
  useChangeGroupSharingMutation,
  useCreateUserStoryMutation,
  useCreateUserStorySlideAudioProgressMutation,
  useCreateUserTourMutation,
  UserStory,
  UserStorySlide,
  UserTour,
  useUpdateUserStorySlideMutation,
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useGroupSharingStore from '../stores/useGroupSharingStore';
import useSearchParams from '../hooks/useSearchParams';
import { getInputToCreateUserTour } from '../helpers/user-tour-helpers';
import { getInputToCreateUserStory } from '../helpers/user-story-helpers';
import useAuthStore from '../stores/useAuthStore';
import useAnalyticsStore from '../stores/useAnalyticsStore';
import useError from '../hooks/useError';
import { getUserStorySlide } from '../helpers/user-story-slide-helpers';
import { useTransaction } from './TransactionContext';
import { getActiveAudioSrc, playAudioByTimer } from '../helpers/media-player-helpers';
import useNetwork from '../hooks/useNetwork';

const useValue = () => {
  const { isGroupSharingStart }: { isGroupSharingStart?: string | null | undefined } = useSearchParams();
  const previousIsGroupSharingStart = usePrevious(isGroupSharingStart);
  const { hasPremiumAccess, activeTransactionsLoading } = useTransaction();
  const { handleBackendError } = useError();
  const { getNetworkStatus } = useNetwork();

  const [createUserTourMutation] = useCreateUserTourMutation();
  const [createUserStoryMutation] = useCreateUserStoryMutation();
  const [updateUserStorySlideMutation] = useUpdateUserStorySlideMutation();
  const [changeGroupSharingMutation] = useChangeGroupSharingMutation();
  const [createUserStorySlideAudioProgressMutation] = useCreateUserStorySlideAudioProgressMutation();

  const isUserInitialised = useAuthStore((state) => state.isUserInitialised);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const user = useAuthStore((state) => state.me);

  const trackStoryViews = useAnalyticsStore((state) => state.trackStoryViews);

  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);

  const currentTour = useMediaPlayerStore((state) => state.currentTour);
  const currentTourStop = useMediaPlayerStore((state) => state.currentTourStop);
  const currentStory = useMediaPlayerStore((state) => state.currentStory);
  const currentStorySlide = useMediaPlayerStore(
    (state) => state.currentStorySlide
  );
  const setCurrentStorySlide = useMediaPlayerStore(
    (state) => state.setCurrentStorySlide
  );
  const previousCurrentTour = usePrevious(currentTour);
  const previousCurrentTourStop = usePrevious(currentTourStop);
  const previousCurrentStory = usePrevious(currentStory);
  const previousCurrentStorySlide = usePrevious(currentStorySlide);

  const playbackState = useMediaPlayerStore((state) => state.playbackState);
  const setPlaybackState = useMediaPlayerStore(
    (state) => state.setPlaybackState
  );
  const resetPlaybackState = useMediaPlayerStore(
    (state) => state.resetPlaybackState
  );
  const previousPlaybackState = usePrevious(playbackState);

  const unloadMedia = useMediaPlayerStore((state) => state.unloadMedia);

  const playingNextSlideEnabled = useRef(false);

  const { navigateToStorySlide } = useStoryPlayer();

  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const {
    isPlaying,
    currentAudioTime,
    currentAudioDuration,
    percentagePlayed,
    isLoading,
  } = playbackState;

  const playbackRate = useMediaPlayerStore((state) => state.playbackRate);
  const setPlaybackRate = useMediaPlayerStore((state) => state.setPlaybackRate);

  // Collection of story slides from each story in the current tour stop,
  // or only slides of the current story if no current tour stop is set
  const currentStorySlides =
    currentTourStop?.stories?.map((story) => story.storySlides).flat() ||
    currentStory?.storySlides;

  let previousStorySlide, nextStorySlide;

  if (currentStorySlides && currentStorySlide) {
    const currentStorySlideIndex = currentStorySlides.findIndex(
      (storySlide) => storySlide?.id === currentStorySlide.id
    );
    previousStorySlide = currentStorySlides[currentStorySlideIndex - 1];
    nextStorySlide = currentStorySlides[currentStorySlideIndex + 1];
  }

  const [
    currentAudioTag,
    currentAudioState,
    currentAudioControls,
    currentAudioRef,
  ] = useAudio({ src: "" });

  const [trackedStorySlide, setTrackedStorySlide] = useState<StorySlide>();
  const [userTour, setUserTour] = useState<DeepPartial<UserTour>>();
  const [userStory, setUserStory] = useState<DeepPartial<UserStory>>();
  const previousUserTour = usePrevious(userTour);
  const previousUserStory = usePrevious(userStory);

  const createUserTour = async (tour: Tour) => {
    await handleBackendError(async () => {
      // create or update the user tour if the request isn't performed yet
      if (tour && userTour?.datoTourId !== tour.id) {
        const { errors, data } = await createUserTourMutation({
          variables: {
            input: getInputToCreateUserTour(tour),
          }
        });
        if (errors) return errors;
        setUserTour(data?.userTour?.createUserTour);
      }
    });
  };

  const createUserStory = async (story: Story) => {
    await handleBackendError(async () => {
      // create or update the user story if the request isn't performed yet
      if (story && userStory?.datoStoryId !== story.id) {
        const { errors, data } = await createUserStoryMutation({
          variables: {
            input: getInputToCreateUserStory(story),
          }
        });
        if (errors) return errors;
        setUserStory(data?.userStory?.createUserStory);
      }
    });
  };

  const updateUserStorySlide = async (userStorySlide: UserStorySlide) => {
    await handleBackendError(async () => {
      const { data, errors } = await updateUserStorySlideMutation({
        variables: {
          input: {
            id: userStorySlide.id,
            audioProgress: 1,
          }
        }
      });
      if (errors) return errors;

      const trackedStoryId = data?.userStorySlide?.updateUserStorySlide?.userStory?.datoStoryId;

      if (trackedStoryId) {
        trackStoryViews([trackedStoryId]);
      }
    });
  };

  const createUserStorySlideAudioProgress = async (input: CreateUserStorySlideAudioProgressInput) => {
    await handleBackendError(async () => {
      const { errors } = await createUserStorySlideAudioProgressMutation({ variables: { input }});
      if (errors) return errors;
    });
  };

  // Track playback state
  useEffect(() => {
    if (currentStorySlide && trackedStorySlide !== currentStorySlide) {
      setTrackedStorySlide(currentStorySlide);
      resetPlaybackState();

      // Re-enable going to next story slide after some time
      //  (to prevent skipping to next story slide when audio is still loading)
      setTimeout(() => {
        playingNextSlideEnabled.current = true;
      }, 2000);
    }

    if (currentAudioState) {
      const isPlaying = currentAudioState.playing;
      const currentAudioTime = currentAudioState.time;
      const currentAudioDuration = isFinite(currentAudioState.duration) ?
        currentAudioState.duration :
        // when generating audio on the fly
        currentAudioTime + 1;
      const percentagePlayed =
        currentAudioTime && currentAudioDuration
          ? currentAudioTime / currentAudioDuration
          : 0;

      const isIos = isPlatform('ios');

      let startAudioTime = 0;
      if (isIos) {
        switch (true) {
          // stop after playing
          case isPlaying && !playbackState.isPlaying:
            startAudioTime = currentAudioTime;
            break;
          // opening new story slide or rewinding
          case playbackState.startAudioTime > currentAudioTime:
            startAudioTime = currentAudioTime < 1 ? 0 : currentAudioTime;
            break;
          // fast forwarding
          case currentAudioTime - playbackState.currentAudioTime > 1:
            startAudioTime = currentAudioTime;
            break;
          default:
            startAudioTime = playbackState.startAudioTime;
        }
      } else {
        startAudioTime = (isPlaying && !playbackState.isPlaying) ? currentAudioTime : playbackState.startAudioTime;
      }

      setPlaybackState({
        isPlaying,
        startAudioTime,
        currentAudioTime,
        currentAudioDuration,
        percentagePlayed,
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    trackedStorySlide,
    currentStorySlide,
    currentAudioState,
    setPlaybackState,
    resetPlaybackState,
    playingNextSlideEnabled,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      const isIos = isPlatform('ios');

      if (
        previousPlaybackState?.isPlaying &&
        !playbackState.isPlaying &&
        playbackState.currentAudioTime !== playbackState.startAudioTime &&
        playbackState.currentAudioTime > playbackState.startAudioTime
      ) {
        const userStorySlide = getUserStorySlide(
          userTour, userStory, currentTour,
          currentTourStop, currentStory, currentStorySlide,
        );

        if (userStorySlide) {
          createUserStorySlideAudioProgress({
            userStorySlideId: userStorySlide.id as string,
            startTime: playbackState.startAudioTime,
            stopTime: playbackState.currentAudioTime,
          });
        }
      } else if (
        previousPlaybackState?.isPlaying && (isEqual(playbackState, initialPlaybackState) || (isIos &&
          !inRange(playbackState.currentAudioTime - previousPlaybackState.currentAudioTime, -1, 1) &&
          previousPlaybackState?.currentAudioTime !== previousPlaybackState?.startAudioTime))
      ) {
        const userStorySlide = getUserStorySlide(
          previousUserTour, previousUserStory, previousCurrentTour,
          previousCurrentTourStop, previousCurrentStory, previousCurrentStorySlide,
        );

        if (userStorySlide) {
          createUserStorySlideAudioProgress({
            userStorySlideId: userStorySlide.id as string,
            startTime: previousPlaybackState.startAudioTime,
            stopTime: previousPlaybackState.currentAudioTime,
          });
        }
      }
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    playbackState, previousPlaybackState, currentTour, previousCurrentTour, currentTourStop, previousCurrentTourStop,
    currentStory, previousCurrentStory, currentStorySlide, previousCurrentStorySlide, userTour, previousUserTour,
    userStory, previousUserStory, isAuthenticated
  ]);

  // Unset the current audio src if the story is an external web story
  useEffect(() => {
    // Unset the audio source if the current story is an external web story
    if (currentAudioRef.current && currentStory?.externalStoryUrl) {
      currentAudioRef.current.src = "";
    }
  }, [currentAudioRef, currentStory]);

  // Load the audio for the current story slide
  useEffect(() => {
    const loadAudio = async () => {
      // skip logic if isGroupSharingStart changes from true to false (for example if the player gets minimized)
      // or if the user is not initialised yet
      if ((!isGroupSharingStart && previousIsGroupSharingStart) || !isUserInitialised || activeTransactionsLoading) return;

      if (currentAudioRef.current && currentStorySlide) {
        // Stop playing the previous audio
        pause();

        // Reset the playback state
        resetPlaybackState();

        // Enable autoplay on the <audio> tag
        if (currentAudioRef.current) {
          // enable autoplay if the user is not in group sharing
          // or received group sharing from subscription (!isGroupSharingStart)
          // or group sharing has isPlaying=true
          currentAudioRef.current.autoplay = !isGroupSharingStart || !!myGroupSharing?.isPlaying;
        }

        let timer: NodeJS.Timeout;

        const loadAudioFromDato = (url: string) => {
          currentAudioRef.current!.src = url;
          currentAudioRef.current!.load();
          timer = playAudioByTimer(isGroupSharingStart, myGroupSharing, currentAudioRef, play, playbackRate, setPlaybackRate);
        }

        const playHlsStream = (url: string) => {
          if (currentAudioRef.current && Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(url);
            hls.attachMedia(currentAudioRef.current);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
              timer = playAudioByTimer(isGroupSharingStart, myGroupSharing, currentAudioRef, play, playbackRate, setPlaybackRate);
            });
          }
        };

        const connectionStatus = await getNetworkStatus();
        const activeAudioSrc = getActiveAudioSrc(currentStorySlide, connectionStatus.connected, hasPremiumAccess, user);

        if (currentAudioRef.current.src !== activeAudioSrc) {
          setPlaybackState({ isLoading: true });

          if (activeAudioSrc.startsWith(process.env.REACT_APP_BACKEND_API_URL!)) {
            const res = await axios.get(activeAudioSrc);
            res.data?.endsWith('.m3u8') ? playHlsStream(res.data) : loadAudioFromDato(res.data);
          } else {
            loadAudioFromDato(activeAudioSrc);
          }
        }

        return () => {
          if (timer) clearTimeout(timer);
        }
      }
    }

    loadAudio();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAudioRef, currentStorySlide, isGroupSharingStart, isUserInitialised, activeTransactionsLoading]
  );

  useEffect(() => {
    const audioElement = currentAudioRef.current;
    if (audioElement) {
      const handleLoadStart = () => setPlaybackState({ isLoading: true });
      const handleCanPlay = () => setPlaybackState({ isLoading: false });
      const handleError = () => setPlaybackState({ isLoading: false });

      audioElement.addEventListener('loadstart', handleLoadStart);
      audioElement.addEventListener('canplay', handleCanPlay);
      audioElement.addEventListener('error', handleError);

      return () => {
        audioElement.removeEventListener('loadstart', handleLoadStart);
        audioElement.removeEventListener('canplay', handleCanPlay);
        audioElement.removeEventListener('error', handleError);
      };
    }
  }, [currentAudioRef, setPlaybackState]);

  // Set the playback rate
  useEffect(() => {
    if (currentAudioRef.current) {
      currentAudioRef.current.playbackRate = playbackRate;
    }
  }, [currentAudioRef, playbackRate]);

  // Set media session information, e.g. for phone lock-screen player widgets
  useEffect(() => {
    if ("mediaSession" in navigator) {
      if (currentStory) {
        const title = currentStory.title;
        if (currentTourStop) {
          title?.concat(` - ${currentTourStop.title}`);
        }
        const artist = currentStory.creatorProfile?.creatorName!;
        const album = currentTour?.title;

        navigator.mediaSession.metadata = new MediaMetadata({
          title: title as string,
          artist,
          album: album as string,
          artwork: [
            {
              src: "/guidable-tour-artwork.png",
              sizes: "180x180",
              type: "image/png",
            },
          ],
        });
      }
    }
  }, [currentTour, currentTourStop, currentStory]);

  // create or update user tour stop or user story with related entities when the user opens the player
  useEffect(() => {
      const createUserEntities = async () => {
        if (currentStory && currentStorySlide) {
          if (isAuthenticated) {
            if (currentTour) {
              await createUserTour(currentTour);
            } else if (!currentTour) {
              await createUserStory(currentStory);
            }
          }
        }
      };

      createUserEntities();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTour, currentStory, currentStorySlide, isAuthenticated]
  );

  useEffect(() => {
    const trackStory = async () => {
      if (currentStory && currentStorySlide) {
        // update audio progress for the story slide on the backend side
        // and set current story as viewed in the analytics store if user is authenticated
        if (isAuthenticated) {
          const userStorySlide = getUserStorySlide(
            userTour, userStory, currentTour,
            currentTourStop, currentStory, currentStorySlide,
          );

          if (userStorySlide) {
            await updateUserStorySlide(userStorySlide as UserStorySlide);
          }
        } else {
          // set current story as viewed in the analytics store if user is not authenticated
          trackStoryViews([currentStory.id]);
        }
      }
    };

    trackStory();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTour, currentTourStop, currentStory, currentStorySlide, userTour, userStory]
  );

  // Track a story view
  useEffect(
    () => {
      if (currentStory) {
        if (mixpanelEnabled) {
          // Track story play event in Mixpanel
          mixpanel.track(MixpanelEvents.PLAY_STORY, {
            tourId: currentTour?.id,
            tourTitle: currentTour?.title,
            tourStopId: currentTourStop?.id,
            tourStopTitle: currentTourStop?.title,
            storyId: currentStory.id,
            storyTitle: currentStory.title,
            storyCreatorId: currentStory.creatorProfile?.id,
            storyCreatorName: currentStory.creatorProfile?.creatorName,
          });

          // Increment stories viewed on user profile in Mixpanel
          mixpanel.people.increment(MixpanelPeopleProperties.STORIES_VIEWED);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStory]
  );

  // Seek to new time if the user selects a different time in the seek bar
  const seekTo = useCallback(
    (newTime: number, updatedByUser = true) => {
      if (newTime !== currentAudioTime) {
        currentAudioControls.seek(newTime);

        // update Group Sharing if the user is in Group Sharing mode
        // and data gets updated by the user (not because of the synchronization)
        if (updatedByUser && myGroupSharing) {
          changeGroupSharingMutation({
            variables: {
              input: isPlaying ? {
                  startAudioTime: moment().subtract(newTime, 'seconds').toDate(),
                } : {
                  startAudioTime: moment().subtract(newTime, 'seconds').toDate(),
                  stopAudioTime: new Date(),
                }
            }
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAudioControls, currentAudioTime]
  );

  const resetMediaPlayerState = useCallback(
    () => {
      pause();
      resetPlaybackState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetPlaybackState]
  );

  const playStory = (story: Story) => {
    const firstStorySlide = story.storySlides?.[0];
    if (story !== currentStory && firstStorySlide) {
      playStorySlide(firstStorySlide);
    }
  };

  const playStorySlide = useCallback(
    async (storySlide: StorySlide) => {
      if (storySlide !== currentStorySlide) {
        resetMediaPlayerState();
        setCurrentStorySlide(storySlide);
      }
    },
    [currentStorySlide, setCurrentStorySlide, resetMediaPlayerState]
  );

  // Play next story slide when the current story slide ends
  useEffect(
    () => {
      if (
        !currentStorySlides ||
        !currentStorySlide ||
        !trackedStorySlide ||
        !currentAudioTime ||
        !currentAudioDuration
      ) {
        return;
      }

      // If the current audio time is less than a minimum threshold then don't execute further
      // Trying to prevent random jumping to next slide for very short audios by preventing this logic from
      // firing in the first couple seconds of an audio being played
      const minimumPlayedTimeInSeconds = 2.0;
      if (currentAudioTime < minimumPlayedTimeInSeconds) {
        return;
      }

      const triggerCallbackBeforeEndOfAudioInMilliseconds = 300; // 0.3s
      const pauseBetweenStorySlidesInMilliseconds = 1200; // 1.2s

      // Only trigger if the current story slide has finished playing
      // Note: Not each browser reports the current time to equal the exact duration of the audio clip,
      //  the current time can also be larger than the audio duration (e.g. in Safari).
      //  Sometimes it can be even a tiny bit smaller than 1, e.g. 0.9996 :/
      //  By triggering the callback slightly before the end of the audio (300ms), we should be on the safe side,
      //  letting the browser finish to play, not worrying if this useEffect would get fired again or not,
      //  only firing it once and waiting for the setTimeout to execute.
      if (
        currentStorySlide === trackedStorySlide &&
        currentAudioTime <
          currentAudioDuration -
            triggerCallbackBeforeEndOfAudioInMilliseconds / 1000
      ) {
        return;
      }

      // Only trigger going to the next slide once for the current slide
      if (!playingNextSlideEnabled.current) {
        return;
      }

      playingNextSlideEnabled.current = false;

      const currentStorySlideIndex = currentStorySlides.findIndex(
        (storySlide) => storySlide?.id === currentStorySlide.id
      );
      const nextStorySlide = currentStorySlides[currentStorySlideIndex + 1];

      if (!nextStorySlide) {
        return;
      }

      // Play next story slide after a short pause
      const jumpToNextSlideTimeout =
        triggerCallbackBeforeEndOfAudioInMilliseconds +
        pauseBetweenStorySlidesInMilliseconds;

      setTimeout(() => {
        navigateToStorySlide({ storySlide: nextStorySlide });
        playStorySlide(nextStorySlide);
      }, jumpToNextSlideTimeout);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentStorySlides,
      currentStorySlide,
      trackedStorySlide,
      currentAudioTime,
      currentAudioDuration,
      playingNextSlideEnabled,
    ]
  );

  const play = () => {
    currentAudioControls.play();
  };

  const pause = () => {
    currentAudioControls.pause();
  };

  const togglePlayPause = (updatedByUser = true) => {
    // update Group Sharing if the user is in Group Sharing mode
    // and data gets updated by the user (not because of the synchronization)
    if (updatedByUser && myGroupSharing) {
      changeGroupSharingMutation({
        variables: {
          input: {
            isPlaying: !isPlaying,
            ...!isPlaying ? {
              startAudioTime: moment().subtract(currentAudioTime, 'seconds').toDate(),
              stopAudioTime: null,
            } : {
              stopAudioTime: new Date(),
            },
          }
        }
      }
      );
    }

    isPlaying ? pause() : play();
  };

  const jumpBackward = (seconds: number) => {
    const newAudioTime = currentAudioTime - seconds;
    currentAudioControls.seek(newAudioTime);

    // update Group Sharing if the user is in Group Sharing mode
    if (myGroupSharing) {
      const startAudioTime = moment().subtract(newAudioTime, 'seconds').toDate();
      changeGroupSharingMutation({ variables: { input: { startAudioTime }}});
    }
  };

  const jumpForward = (seconds: number) => {
    const newAudioTime = currentAudioTime + seconds;
    currentAudioControls.seek(newAudioTime);

    // update Group Sharing if the user is in Group Sharing mode
    if (myGroupSharing) {
      const startAudioTime = moment().subtract(newAudioTime, 'seconds').toDate();
      changeGroupSharingMutation({variables: { input: { startAudioTime } } });
    }
  };

  const togglePlaybackRate = () => {
    let newPlaybackRate;
    if (playbackRate === 1) {
      newPlaybackRate = 1.25;
    } else if (playbackRate === 1.25) {
      newPlaybackRate = 1.5;
    } else if (playbackRate === 1.5) {
      newPlaybackRate = 1.75;
    } else if (playbackRate === 1.75) {
      newPlaybackRate = 2;
    } else {
      newPlaybackRate = 1;
    }

    setPlaybackRate(newPlaybackRate);

    // update Group Sharing if the user is in Group Sharing mode
    if (myGroupSharing) {
      changeGroupSharingMutation({ variables: { input: { playbackRate: newPlaybackRate } } });
    }
  };

  const unloadMediaPlayer = () => {
    pause();
    unloadMedia();
  };

  return {
    // Currently playing records
    currentTour,
    currentTourStop,
    currentStory,
    currentStorySlides,
    currentStorySlide,
    previousStorySlide,
    nextStorySlide,

    // Start playing stories
    playStory,
    playStorySlide,

    // Audio tag
    currentAudioTag,

    // Media state
    isPlaying,
    currentAudioTime,
    currentAudioDuration,
    percentagePlayed,
    isLoading,

    // Media controls
    play,
    pause,
    togglePlayPause,
    jumpBackward,
    jumpForward,
    seekTo,

    // Playback rate
    playbackRate,
    togglePlaybackRate,
    setPlaybackRate,

    // Reset state
    unloadMediaPlayer,
  };
};

const MediaPlayerContext = createContext({} as ReturnType<typeof useValue>);

const MediaPlayerProvider: React.FC = ({ children }) => {
  const { currentAudioTag, ...rest } = useValue();

  return (
    <MediaPlayerContext.Provider value={{ currentAudioTag, ...rest }}>
      {children}

      <div id="current-audio-tag">{currentAudioTag}</div>
    </MediaPlayerContext.Provider>
  );
};

const useMediaPlayer = () => {
  return useContext(MediaPlayerContext);
};

export { MediaPlayerProvider, useMediaPlayer };
