// THIS FILE IS GENERATED WITH GRAPHQL-CODEGEN, DO NOT EDIT!

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
};

/** App access type */
export enum AppAccessType {
  Premium = "Premium",
  Tour = "Tour",
}

export type ApplicationSettingsQuery = {
  __typename?: "ApplicationSettingsQuery";
  /** Get supported frontend app version for platforms */
  getSupportedAppVersion: SupportedAppVersion;
};

export type AttachMeToGroupSharingInput = {
  groupSharingId: Scalars["String"];
};

export type AuthMutation = {
  __typename?: "AuthMutation";
  /** Log out */
  logOut: Scalars["Boolean"];
  /** Resend reset password verification email */
  resendResetPasswordVerificationEmail: Scalars["Boolean"];
  /** Resend sign up verification email */
  resendSignUpVerificationEmail: Scalars["Boolean"];
  /** Reset password */
  resetPassword: Scalars["Boolean"];
  /** Confirm password resetting and get user */
  resetPasswordConfirm: Scalars["Boolean"];
  /** Sign in and get user or null if user did not complete sign up */
  signIn?: Maybe<User>;
  /** Sign up */
  signUp: Scalars["Boolean"];
  /** Confirm verification code and get user */
  signUpConfirm: User;
  /** Sign in with social network and get user */
  socialSignIn?: Maybe<User>;
};

export type AuthMutationResendResetPasswordVerificationEmailArgs = {
  input: ResendResetPasswordVerificationEmailInput;
};

export type AuthMutationResendSignUpVerificationEmailArgs = {
  input: ResendSignUpVerificationEmailInput;
};

export type AuthMutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type AuthMutationResetPasswordConfirmArgs = {
  input: ResetPasswordConfirmInput;
};

export type AuthMutationSignInArgs = {
  input: UserSignInInput;
};

export type AuthMutationSignUpArgs = {
  input: UserSignUpInput;
};

export type AuthMutationSignUpConfirmArgs = {
  input: UserSignUpConfirmInput;
};

export type AuthMutationSocialSignInArgs = {
  input: SocialSignInInput;
};

export type ChangeGroupSharingInput = {
  isPlaying?: InputMaybe<Scalars["Boolean"]>;
  navigationUrl?: InputMaybe<Scalars["String"]>;
  playbackRate?: InputMaybe<Scalars["Float"]>;
  startAudioTime?: InputMaybe<Scalars["DateTime"]>;
  stopAudioTime?: InputMaybe<Scalars["DateTime"]>;
};

export type ChangePasswordInput = {
  newPassword: Scalars["String"];
  password: Scalars["String"];
};

export type CityLocationInput = {
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type CreateCityInput = {
  countryCode: Scalars["String"];
  location: CityLocationInput;
  name: Scalars["String"];
};

export type CreateGroupSharingInput = {
  isPlaying?: InputMaybe<Scalars["Boolean"]>;
  navigationUrl?: InputMaybe<Scalars["String"]>;
  playbackRate?: InputMaybe<Scalars["Float"]>;
  startAudioTime?: InputMaybe<Scalars["DateTime"]>;
  stopAudioTime?: InputMaybe<Scalars["DateTime"]>;
};

export type CreateProfileInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type CreateStoryByGptInput = {
  datoCategoryIds: Array<Scalars["String"]>;
  datoCityId: Scalars["String"];
  datoExperienceLevelId?: InputMaybe<Scalars["String"]>;
  datoSightseeingSpotId: Scalars["String"];
};

export type CreateTourByGptInput = {
  cityData?: InputMaybe<CreateCityInput>;
  datoCategoryIds: Array<Scalars["String"]>;
  datoCityId?: InputMaybe<Scalars["String"]>;
  datoExperienceLevelId: Scalars["String"];
  tourDuration: Scalars["Float"];
};

export type CreateUserQuizInput = {
  datoQuizId: Scalars["String"];
  isBlocking?: InputMaybe<Scalars["Boolean"]>;
  userQuizTasks: Array<CreateUserQuizTaskInput>;
};

export type CreateUserQuizTaskInput = {
  datoQuizTaskId: Scalars["String"];
  quizTaskType: QuizTaskType;
  referenceId?: InputMaybe<Scalars["String"]>;
};

export type CreateUserStoryInput = {
  datoStoryId: Scalars["String"];
  userStorySlides: Array<CreateUserStorySlideInput>;
};

export type CreateUserStorySlideAudioProgressInput = {
  startTime: Scalars["Float"];
  stopTime: Scalars["Float"];
  userStorySlideId: Scalars["String"];
};

export type CreateUserStorySlideInput = {
  datoStorySlideId: Scalars["String"];
  userQuiz?: InputMaybe<CreateUserQuizInput>;
};

export type CreateUserTourInput = {
  datoCreatorProfileId?: InputMaybe<Scalars["String"]>;
  datoTourId: Scalars["String"];
  userTourStops: Array<CreateUserTourStopInput>;
};

export type CreateUserTourStopInput = {
  datoTourStopId: Scalars["String"];
  userStories: Array<CreateUserStoryInput>;
};

/** Creator type */
export enum CreatorType {
  UserWithGPT = "UserWithGPT",
}

export type DatoQuery = {
  __typename?: "DatoQuery";
  /** Get number of cities that have at least one discoverable story or tour */
  getNumberOfCitiesWithContent: Scalars["Int"];
};

export type DeleteUserConfirmInput = {
  verificationCode: Scalars["String"];
};

export type EvaluateStoryInput = {
  datoStoryId: Scalars["String"];
  evaluationType: EvaluationType;
};

/** Evaluation type */
export enum EvaluationType {
  Dislike = "Dislike",
  Like = "Like",
}

export type GenerateTourAudioInput = {
  datoTourId: Scalars["String"];
};

export type GetStoriesLikeStatisticsInput = {
  datoStoryIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type GetStoryLikeStatisticsInput = {
  datoStoryId: Scalars["String"];
};

export type GetTourRatingStatisticsInput = {
  datoTourId: Scalars["String"];
};

export type GetToursRatingStatisticsInput = {
  datoTourIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type GetUserQuizTaskByInput = {
  fieldName: Scalars["String"];
  fieldValue: Scalars["String"];
};

export type GetUserStoryEvaluationInput = {
  datoStoryId: Scalars["String"];
};

export type GetUserTourAudioStatisticsInput = {
  datoTourId: Scalars["String"];
};

export type GetUserTourInput = {
  datoTourId: Scalars["String"];
};

export type GetUserToursAudioStatisticsInput = {
  datoTourIds?: InputMaybe<Array<Scalars["String"]>>;
};

/** Group sharing */
export type GroupSharing = {
  __typename?: "GroupSharing";
  id: Scalars["ID"];
  isPlaying?: Maybe<Scalars["Boolean"]>;
  navigationUrl?: Maybe<Scalars["String"]>;
  playbackRate?: Maybe<Scalars["Float"]>;
  startAudioTime?: Maybe<Scalars["DateTime"]>;
  stopAudioTime?: Maybe<Scalars["DateTime"]>;
  users?: Maybe<Array<User>>;
};

export type GroupSharingMutation = {
  __typename?: "GroupSharingMutation";
  /** Attach current user to group sharing */
  attachMeToGroupSharing: GroupSharing;
  /** Change group sharing */
  changeGroupSharing: GroupSharing;
  /** Create group sharing */
  createGroupSharing: GroupSharing;
  /** Attach current user from group sharing */
  detachMeFromGroupSharing: Scalars["Boolean"];
};

export type GroupSharingMutationAttachMeToGroupSharingArgs = {
  input: AttachMeToGroupSharingInput;
};

export type GroupSharingMutationChangeGroupSharingArgs = {
  input: ChangeGroupSharingInput;
};

export type GroupSharingMutationCreateGroupSharingArgs = {
  input: CreateGroupSharingInput;
};

export type GroupSharingQuery = {
  __typename?: "GroupSharingQuery";
  /** Get group sharing of current user */
  getMyGroupSharing?: Maybe<GroupSharing>;
};

export type Mutation = {
  __typename?: "Mutation";
  auth: AuthMutation;
  groupSharing: GroupSharingMutation;
  promoCode: PromoCodeMutation;
  story: StoryMutation;
  storyEvaluation: StoryEvaluationMutation;
  tour: TourMutation;
  user: UserMutation;
  userQuizTask: UserQuizTaskMutation;
  userStory: UserStoryMutation;
  userStorySlide: UserStorySlideMutation;
  userStorySlideAudioProgress: UserStorySlideAudioProgressMutation;
  userTour: UserTourMutation;
};

/** Profile */
export type Profile = {
  __typename?: "Profile";
  birthday?: Maybe<Scalars["DateTime"]>;
  datoGenderId?: Maybe<Scalars["String"]>;
  datoVoiceId?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type PromoCodeMutation = {
  __typename?: "PromoCodeMutation";
  /** Redeem promo code */
  redeemPromoCode: Transaction;
};

export type PromoCodeMutationRedeemPromoCodeArgs = {
  input: RedeemPromoCodeInput;
};

export type Query = {
  __typename?: "Query";
  applicationSettings: ApplicationSettingsQuery;
  dato: DatoQuery;
  groupSharing: GroupSharingQuery;
  storyEvaluation: StoryEvaluationQuery;
  tourCreationRequest: TourCreationRequestQuery;
  tourReview: TourReviewQuery;
  transaction: TransactionQuery;
  user: UserQuery;
  userQuizTask: UserQuizTaskQuery;
  userStory: UserStoryQuery;
  userTour: UserTourQuery;
};

/** Quiz task type */
export enum QuizTaskType {
  LocationTask = "LocationTask",
  MultipleChoiceQuestion = "MultipleChoiceQuestion",
  QrCodeTask = "QrCodeTask",
  TextInput = "TextInput",
}

export type RedeemPromoCodeInput = {
  code: Scalars["String"];
};

export type ResendResetPasswordVerificationEmailInput = {
  email: Scalars["String"];
};

export type ResendSignUpVerificationEmailInput = {
  email: Scalars["String"];
};

export type ResetPasswordConfirmInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type ResetPasswordInput = {
  email: Scalars["String"];
};

export type ResetUserTourInput = {
  datoTourId: Scalars["String"];
};

export type SetOneSignalSubscriptionIdInput = {
  oneSignalSubscriptionId: Scalars["String"];
};

/** Social provider */
export enum SocialProvider {
  Apple = "Apple",
  Facebook = "Facebook",
  Google = "Google",
}

export type SocialSignInInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  redirectUri?: InputMaybe<Scalars["String"]>;
  socialProvider: SocialProvider;
};

/** Story creation request */
export type StoryCreationRequest = {
  __typename?: "StoryCreationRequest";
  id: Scalars["ID"];
  status: StoryCreationRequestStatus;
  timezone: Scalars["String"];
  userStory?: Maybe<UserStory>;
  userStoryId?: Maybe<Scalars["String"]>;
};

/** Story creation request status */
export enum StoryCreationRequestStatus {
  Failure = "Failure",
  InProgress = "InProgress",
  Success = "Success",
}

/** Story evaluation */
export type StoryEvaluation = {
  __typename?: "StoryEvaluation";
  datoStoryId: Scalars["String"];
  evaluationType: EvaluationType;
  id: Scalars["ID"];
  userId: Scalars["String"];
};

export type StoryEvaluationMutation = {
  __typename?: "StoryEvaluationMutation";
  /** Evaluate story */
  evaluateStory: StoryEvaluation;
};

export type StoryEvaluationMutationEvaluateStoryArgs = {
  input: EvaluateStoryInput;
};

export type StoryEvaluationQuery = {
  __typename?: "StoryEvaluationQuery";
  /** Get story evaluation for current user */
  getMyStoryEvaluation?: Maybe<StoryEvaluation>;
  /** Get like statistics for stories */
  getStoriesLikeStatistics: Array<StoryLikeStatistics>;
  /** Get like statistics for story */
  getStoryLikeStatistics?: Maybe<StoryLikeStatistics>;
};

export type StoryEvaluationQueryGetMyStoryEvaluationArgs = {
  input: GetUserStoryEvaluationInput;
};

export type StoryEvaluationQueryGetStoriesLikeStatisticsArgs = {
  input?: InputMaybe<GetStoriesLikeStatisticsInput>;
};

export type StoryEvaluationQueryGetStoryLikeStatisticsArgs = {
  input: GetStoryLikeStatisticsInput;
};

/** Like statistics for the story */
export type StoryLikeStatistics = {
  __typename?: "StoryLikeStatistics";
  datoStoryId: Scalars["String"];
  totalLikesAmount?: Maybe<Scalars["Float"]>;
};

export type StoryMutation = {
  __typename?: "StoryMutation";
  /** Create story by GPT */
  createStoryByGpt: Scalars["Boolean"];
};

export type StoryMutationCreateStoryByGptArgs = {
  input: CreateStoryByGptInput;
};

export type Subscription = {
  __typename?: "Subscription";
  groupSharingChanged: GroupSharing;
  storyCreationRequestUpdated: StoryCreationRequest;
  supportedAppVersionChanged: SupportedAppVersion;
  tourAudioGenerationRequestUpdated: TourAudioGenerationRequest;
  tourCreationRequestUpdated: TourCreationRequest;
  transactionCreated: Transaction;
};

/** Supported frontend app version for platforms */
export type SupportedAppVersion = {
  __typename?: "SupportedAppVersion";
  android?: Maybe<Scalars["String"]>;
  ios?: Maybe<Scalars["String"]>;
};

/** Tour audio generation request */
export type TourAudioGenerationRequest = {
  __typename?: "TourAudioGenerationRequest";
  datoTourId: Scalars["String"];
  datoVoiceId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  status: TourAudioGenerationRequestStatus;
};

/** Tour audio generation request status */
export enum TourAudioGenerationRequestStatus {
  Failure = "Failure",
  InProgress = "InProgress",
  Success = "Success",
}

/** Tour creation request */
export type TourCreationRequest = {
  __typename?: "TourCreationRequest";
  id: Scalars["ID"];
  status: TourCreationRequestStatus;
  timezone: Scalars["String"];
  userTour?: Maybe<UserTour>;
  userTourId?: Maybe<Scalars["String"]>;
};

export type TourCreationRequestQuery = {
  __typename?: "TourCreationRequestQuery";
  /** Get the tour creation request statistics for the user */
  getTourCreationRequestStatisticsByUser: TourCreationRequestStatisticsByUser;
};

/** Tour Creation Request Statistics By User */
export type TourCreationRequestStatisticsByUser = {
  __typename?: "TourCreationRequestStatisticsByUser";
  inProgress: Scalars["Int"];
  success: Scalars["Int"];
  total: Scalars["Int"];
  userId: Scalars["ID"];
};

/** Tour creation request status */
export enum TourCreationRequestStatus {
  Failure = "Failure",
  InProgress = "InProgress",
  Success = "Success",
}

export type TourMutation = {
  __typename?: "TourMutation";
  /** Create tour by GPT */
  createTourByGpt: Scalars["Boolean"];
  /** Generate tour audio */
  generateTourAudio: TourAudioGenerationRequest;
};

export type TourMutationCreateTourByGptArgs = {
  input: CreateTourByGptInput;
};

export type TourMutationGenerateTourAudioArgs = {
  input: GenerateTourAudioInput;
};

/** Rating statistics for the tour */
export type TourRatingStatistics = {
  __typename?: "TourRatingStatistics";
  averageRating?: Maybe<Scalars["Float"]>;
  datoTourId: Scalars["String"];
  totalReviewsAmount?: Maybe<Scalars["Float"]>;
};

export type TourReviewQuery = {
  __typename?: "TourReviewQuery";
  /** Get rating statistics for tour */
  getTourRatingStatistics?: Maybe<TourRatingStatistics>;
  /** Get rating statistics for tours */
  getToursRatingStatistics: Array<TourRatingStatistics>;
};

export type TourReviewQueryGetTourRatingStatisticsArgs = {
  input: GetTourRatingStatisticsInput;
};

export type TourReviewQueryGetToursRatingStatisticsArgs = {
  input?: InputMaybe<GetToursRatingStatisticsInput>;
};

/** Transaction */
export type Transaction = {
  __typename?: "Transaction";
  accessType: AppAccessType;
  expirationDate: Scalars["DateTime"];
  id: Scalars["ID"];
  productId: Scalars["String"];
  promotionalCode?: Maybe<Scalars["String"]>;
  purchaseDate: Scalars["DateTime"];
  tourId?: Maybe<Scalars["String"]>;
  user: User;
  userId: Scalars["String"];
};

export type TransactionQuery = {
  __typename?: "TransactionQuery";
  /** Get active transactions for the user */
  getActiveTransactions: Array<Transaction>;
};

export type UpdateProfileInput = {
  birthday?: InputMaybe<Scalars["DateTime"]>;
  datoGenderId?: InputMaybe<Scalars["String"]>;
  datoVoiceId?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type UpdateUserInput = {
  profile?: InputMaybe<UpdateProfileInput>;
};

export type UpdateUserQuizTaskInput = {
  answerId?: InputMaybe<Scalars["String"]>;
  answerLocation?: InputMaybe<Scalars["String"]>;
  answerText?: InputMaybe<Scalars["String"]>;
  datoQuizTaskId: Scalars["String"];
  isCompleted?: InputMaybe<Scalars["Boolean"]>;
  isCorrectAnswer?: InputMaybe<Scalars["Boolean"]>;
  isStarted?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateUserStorySlideInput = {
  audioProgress?: InputMaybe<Scalars["Float"]>;
  id: Scalars["String"];
};

/** User */
export type User = {
  __typename?: "User";
  email: Scalars["String"];
  groupSharing?: Maybe<GroupSharing>;
  id: Scalars["ID"];
  profile?: Maybe<Profile>;
  status: UserStatus;
  token?: Maybe<Scalars["String"]>;
};

export type UserMutation = {
  __typename?: "UserMutation";
  /** Change password for current user */
  changePassword: Scalars["Boolean"];
  /** Send verification email to delete user */
  deleteMe: Scalars["Boolean"];
  /** Confirm deletion of the current user */
  deleteMeConfirm: Scalars["Boolean"];
  /** Resend verification email to delete user */
  resendDeleteMeVerificationEmail: Scalars["Boolean"];
  /** Set OneSignal subscription id */
  setOneSignalSubscriptionId: Scalars["Boolean"];
  /** Update current user */
  updateMe: User;
};

export type UserMutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type UserMutationDeleteMeConfirmArgs = {
  input: DeleteUserConfirmInput;
};

export type UserMutationSetOneSignalSubscriptionIdArgs = {
  input: SetOneSignalSubscriptionIdInput;
};

export type UserMutationUpdateMeArgs = {
  input: UpdateUserInput;
};

export type UserQuery = {
  __typename?: "UserQuery";
  /** Get current user */
  me?: Maybe<User>;
};

/** User quiz */
export type UserQuiz = {
  __typename?: "UserQuiz";
  datoQuizId: Scalars["String"];
  id: Scalars["ID"];
  isBlocking?: Maybe<Scalars["Boolean"]>;
  isCompleted?: Maybe<Scalars["Boolean"]>;
  userQuizTasks?: Maybe<Array<UserQuizTask>>;
  userStorySlide?: Maybe<UserStorySlide>;
  userStorySlideId?: Maybe<Scalars["String"]>;
};

/** User quiz task */
export type UserQuizTask = {
  __typename?: "UserQuizTask";
  answerId?: Maybe<Scalars["String"]>;
  answerLocation?: Maybe<Scalars["String"]>;
  answerText?: Maybe<Scalars["String"]>;
  datoQuizTaskId: Scalars["String"];
  id: Scalars["ID"];
  isCompleted?: Maybe<Scalars["Boolean"]>;
  isCorrectAnswer?: Maybe<Scalars["Boolean"]>;
  isStarted?: Maybe<Scalars["Boolean"]>;
  quizTaskType: QuizTaskType;
  referenceId?: Maybe<Scalars["String"]>;
  userQuiz: UserQuiz;
  userQuizId: Scalars["String"];
};

export type UserQuizTaskMutation = {
  __typename?: "UserQuizTaskMutation";
  /** Update user quiz task */
  updateUserQuizTask: UserQuizTask;
};

export type UserQuizTaskMutationUpdateUserQuizTaskArgs = {
  input: UpdateUserQuizTaskInput;
};

export type UserQuizTaskQuery = {
  __typename?: "UserQuizTaskQuery";
  /** Get user quiz task by field value */
  getUserQuizTaskBy?: Maybe<UserQuizTask>;
};

export type UserQuizTaskQueryGetUserQuizTaskByArgs = {
  input: GetUserQuizTaskByInput;
};

export type UserSignInInput = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type UserSignUpConfirmInput = {
  email: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type UserSignUpInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  profile: CreateProfileInput;
};

/** User status */
export enum UserStatus {
  Active = "Active",
  Unverified = "Unverified",
}

/** User story */
export type UserStory = {
  __typename?: "UserStory";
  creatorType?: Maybe<CreatorType>;
  datoStoryId: Scalars["String"];
  id: Scalars["ID"];
  userId: Scalars["String"];
  userStorySlides?: Maybe<Array<UserStorySlide>>;
  userTourStop?: Maybe<UserTourStop>;
  userTourStopId?: Maybe<Scalars["String"]>;
};

export type UserStoryMutation = {
  __typename?: "UserStoryMutation";
  /** Create or update user story with related entities */
  createUserStory: UserStory;
};

export type UserStoryMutationCreateUserStoryArgs = {
  input: CreateUserStoryInput;
};

export type UserStoryQuery = {
  __typename?: "UserStoryQuery";
  /** Get user stories created by user */
  getUserStoriesCreatedByMe: Array<UserStory>;
  /** Get viewed user stories */
  getViewedUserStories: Array<UserStory>;
};

/** User story slide */
export type UserStorySlide = {
  __typename?: "UserStorySlide";
  audioProgress: Scalars["Float"];
  datoStorySlideId: Scalars["String"];
  id: Scalars["ID"];
  userQuiz?: Maybe<UserQuiz>;
  userStory: UserStory;
  userStoryId: Scalars["String"];
};

/** User story slide audio progress */
export type UserStorySlideAudioProgress = {
  __typename?: "UserStorySlideAudioProgress";
  id: Scalars["ID"];
  startTime: Scalars["Float"];
  stopTime: Scalars["Float"];
  timezone: Scalars["String"];
  userStorySlide: UserStorySlide;
  userStorySlideId: Scalars["String"];
};

export type UserStorySlideAudioProgressMutation = {
  __typename?: "UserStorySlideAudioProgressMutation";
  /** Create user story slide audio progress */
  createUserStorySlideAudioProgress: UserStorySlideAudioProgress;
};

export type UserStorySlideAudioProgressMutationCreateUserStorySlideAudioProgressArgs =
  {
    input: CreateUserStorySlideAudioProgressInput;
  };

export type UserStorySlideMutation = {
  __typename?: "UserStorySlideMutation";
  /** Update user story slide */
  updateUserStorySlide: UserStorySlide;
};

export type UserStorySlideMutationUpdateUserStorySlideArgs = {
  input: UpdateUserStorySlideInput;
};

/** User tour */
export type UserTour = {
  __typename?: "UserTour";
  creatorType?: Maybe<CreatorType>;
  datoCreatorProfileId?: Maybe<Scalars["String"]>;
  datoTourId: Scalars["String"];
  id: Scalars["ID"];
  userId: Scalars["String"];
  userTourStops?: Maybe<Array<UserTourStop>>;
};

/** Audio statistics for the user tour */
export type UserTourAudioStatistics = {
  __typename?: "UserTourAudioStatistics";
  audioProgress?: Maybe<Scalars["Float"]>;
  datoTourId: Scalars["String"];
  userTourStopsAudioStatistics?: Maybe<Array<UserTourStopAudioStatistics>>;
};

export type UserTourMutation = {
  __typename?: "UserTourMutation";
  /** Create or update user tour with related entities */
  createUserTour: UserTour;
  /** Reset user tour with related entities */
  resetUserTour: UserTour;
};

export type UserTourMutationCreateUserTourArgs = {
  input: CreateUserTourInput;
};

export type UserTourMutationResetUserTourArgs = {
  input: ResetUserTourInput;
};

export type UserTourQuery = {
  __typename?: "UserTourQuery";
  /** Get user tour */
  getUserTour?: Maybe<UserTour>;
  /** Get user tour audio statistics */
  getUserTourAudioStatistics?: Maybe<UserTourAudioStatistics>;
  /** Get user tours audio statistics */
  getUserToursAudioStatistics?: Maybe<Array<UserTourAudioStatistics>>;
  /** Get user tours created by user */
  getUserToursCreatedByMe: Array<UserTour>;
};

export type UserTourQueryGetUserTourArgs = {
  input: GetUserTourInput;
};

export type UserTourQueryGetUserTourAudioStatisticsArgs = {
  input: GetUserTourAudioStatisticsInput;
};

export type UserTourQueryGetUserToursAudioStatisticsArgs = {
  input?: InputMaybe<GetUserToursAudioStatisticsInput>;
};

/** User tour stop */
export type UserTourStop = {
  __typename?: "UserTourStop";
  datoTourStopId: Scalars["String"];
  id: Scalars["ID"];
  userStories?: Maybe<Array<UserStory>>;
  userTour: UserTour;
  userTourId: Scalars["String"];
};

/** Audio statistics for the user tour stop */
export type UserTourStopAudioStatistics = {
  __typename?: "UserTourStopAudioStatistics";
  audioProgress?: Maybe<Scalars["Float"]>;
  datoTourStopId: Scalars["String"];
};

export type GetSupportedAppVersionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSupportedAppVersionQuery = {
  __typename?: "Query";
  applicationSettings: {
    __typename?: "ApplicationSettingsQuery";
    getSupportedAppVersion: {
      __typename?: "SupportedAppVersion";
      android?: string | null;
      ios?: string | null;
    };
  };
};

export type SupportedAppVersionChangedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SupportedAppVersionChangedSubscription = {
  __typename?: "Subscription";
  supportedAppVersionChanged: {
    __typename?: "SupportedAppVersion";
    android?: string | null;
    ios?: string | null;
  };
};

export type LogOutMutationVariables = Exact<{ [key: string]: never }>;

export type LogOutMutation = {
  __typename?: "Mutation";
  auth: { __typename?: "AuthMutation"; logOut: boolean };
};

export type ResendResetPasswordVerificationEmailMutationVariables = Exact<{
  input: ResendResetPasswordVerificationEmailInput;
}>;

export type ResendResetPasswordVerificationEmailMutation = {
  __typename?: "Mutation";
  auth: {
    __typename?: "AuthMutation";
    resendResetPasswordVerificationEmail: boolean;
  };
};

export type ResendSignUpVerificationEmailMutationVariables = Exact<{
  input: ResendSignUpVerificationEmailInput;
}>;

export type ResendSignUpVerificationEmailMutation = {
  __typename?: "Mutation";
  auth: { __typename?: "AuthMutation"; resendSignUpVerificationEmail: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  auth: { __typename?: "AuthMutation"; resetPassword: boolean };
};

export type ResetPasswordConfirmMutationVariables = Exact<{
  input: ResetPasswordConfirmInput;
}>;

export type ResetPasswordConfirmMutation = {
  __typename?: "Mutation";
  auth: { __typename?: "AuthMutation"; resetPasswordConfirm: boolean };
};

export type SignInMutationVariables = Exact<{
  input: UserSignInInput;
}>;

export type SignInMutation = {
  __typename?: "Mutation";
  auth: {
    __typename?: "AuthMutation";
    signIn?: {
      __typename?: "User";
      id: string;
      email: string;
      token?: string | null;
      status: UserStatus;
      profile?: {
        __typename?: "Profile";
        id: string;
        userId: string;
        firstName?: string | null;
        lastName?: string | null;
        birthday?: Date | null;
        datoGenderId?: string | null;
        datoVoiceId?: string | null;
      } | null;
      groupSharing?: {
        __typename?: "GroupSharing";
        id: string;
        navigationUrl?: string | null;
        isPlaying?: boolean | null;
        startAudioTime?: Date | null;
        stopAudioTime?: Date | null;
        playbackRate?: number | null;
        users?: Array<{
          __typename?: "User";
          id: string;
          email: string;
          status: UserStatus;
          profile?: {
            __typename?: "Profile";
            id: string;
            userId: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }> | null;
      } | null;
    } | null;
  };
};

export type SignUpMutationVariables = Exact<{
  input: UserSignUpInput;
}>;

export type SignUpMutation = {
  __typename?: "Mutation";
  auth: { __typename?: "AuthMutation"; signUp: boolean };
};

export type SignUpConfirmMutationVariables = Exact<{
  input: UserSignUpConfirmInput;
}>;

export type SignUpConfirmMutation = {
  __typename?: "Mutation";
  auth: {
    __typename?: "AuthMutation";
    signUpConfirm: {
      __typename?: "User";
      id: string;
      email: string;
      token?: string | null;
      status: UserStatus;
      profile?: {
        __typename?: "Profile";
        id: string;
        userId: string;
        firstName?: string | null;
        lastName?: string | null;
        birthday?: Date | null;
        datoGenderId?: string | null;
        datoVoiceId?: string | null;
      } | null;
      groupSharing?: {
        __typename?: "GroupSharing";
        id: string;
        navigationUrl?: string | null;
        isPlaying?: boolean | null;
        startAudioTime?: Date | null;
        stopAudioTime?: Date | null;
        playbackRate?: number | null;
        users?: Array<{
          __typename?: "User";
          id: string;
          email: string;
          status: UserStatus;
          profile?: {
            __typename?: "Profile";
            id: string;
            userId: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }> | null;
      } | null;
    };
  };
};

export type SocialSignInMutationVariables = Exact<{
  input: SocialSignInInput;
}>;

export type SocialSignInMutation = {
  __typename?: "Mutation";
  auth: {
    __typename?: "AuthMutation";
    socialSignIn?: {
      __typename?: "User";
      id: string;
      email: string;
      token?: string | null;
      status: UserStatus;
      profile?: {
        __typename?: "Profile";
        id: string;
        userId: string;
        firstName?: string | null;
        lastName?: string | null;
        birthday?: Date | null;
        datoGenderId?: string | null;
        datoVoiceId?: string | null;
      } | null;
      groupSharing?: {
        __typename?: "GroupSharing";
        id: string;
        navigationUrl?: string | null;
        isPlaying?: boolean | null;
        startAudioTime?: Date | null;
        stopAudioTime?: Date | null;
        playbackRate?: number | null;
        users?: Array<{
          __typename?: "User";
          id: string;
          email: string;
          status: UserStatus;
          profile?: {
            __typename?: "Profile";
            id: string;
            userId: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }> | null;
      } | null;
    } | null;
  };
};

export type GetNumberOfCitiesWithContentQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNumberOfCitiesWithContentQuery = {
  __typename?: "Query";
  dato: { __typename?: "DatoQuery"; getNumberOfCitiesWithContent: number };
};

export type AttachMeToGroupSharingMutationVariables = Exact<{
  input: AttachMeToGroupSharingInput;
}>;

export type AttachMeToGroupSharingMutation = {
  __typename?: "Mutation";
  groupSharing: {
    __typename?: "GroupSharingMutation";
    attachMeToGroupSharing: {
      __typename?: "GroupSharing";
      id: string;
      navigationUrl?: string | null;
      isPlaying?: boolean | null;
      startAudioTime?: Date | null;
      stopAudioTime?: Date | null;
      playbackRate?: number | null;
      users?: Array<{
        __typename?: "User";
        id: string;
        email: string;
        status: UserStatus;
        profile?: {
          __typename?: "Profile";
          id: string;
          userId: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      }> | null;
    };
  };
};

export type ChangeGroupSharingMutationVariables = Exact<{
  input: ChangeGroupSharingInput;
}>;

export type ChangeGroupSharingMutation = {
  __typename?: "Mutation";
  groupSharing: {
    __typename?: "GroupSharingMutation";
    changeGroupSharing: { __typename?: "GroupSharing"; id: string };
  };
};

export type CreateGroupSharingMutationVariables = Exact<{
  input: CreateGroupSharingInput;
}>;

export type CreateGroupSharingMutation = {
  __typename?: "Mutation";
  groupSharing: {
    __typename?: "GroupSharingMutation";
    createGroupSharing: {
      __typename?: "GroupSharing";
      id: string;
      navigationUrl?: string | null;
      isPlaying?: boolean | null;
      startAudioTime?: Date | null;
      stopAudioTime?: Date | null;
      playbackRate?: number | null;
      users?: Array<{
        __typename?: "User";
        id: string;
        email: string;
        status: UserStatus;
        profile?: {
          __typename?: "Profile";
          id: string;
          userId: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      }> | null;
    };
  };
};

export type DetachMeFromGroupSharingMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DetachMeFromGroupSharingMutation = {
  __typename?: "Mutation";
  groupSharing: {
    __typename?: "GroupSharingMutation";
    detachMeFromGroupSharing: boolean;
  };
};

export type GetMyGroupSharingQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyGroupSharingQuery = {
  __typename?: "Query";
  groupSharing: {
    __typename?: "GroupSharingQuery";
    getMyGroupSharing?: {
      __typename?: "GroupSharing";
      id: string;
      navigationUrl?: string | null;
      isPlaying?: boolean | null;
      startAudioTime?: Date | null;
      stopAudioTime?: Date | null;
      playbackRate?: number | null;
      users?: Array<{
        __typename?: "User";
        id: string;
        email: string;
        status: UserStatus;
        profile?: {
          __typename?: "Profile";
          id: string;
          userId: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      }> | null;
    } | null;
  };
};

export type GroupSharingChangedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GroupSharingChangedSubscription = {
  __typename?: "Subscription";
  groupSharingChanged: {
    __typename?: "GroupSharing";
    id: string;
    navigationUrl?: string | null;
    isPlaying?: boolean | null;
    startAudioTime?: Date | null;
    stopAudioTime?: Date | null;
    playbackRate?: number | null;
    users?: Array<{
      __typename?: "User";
      id: string;
      email: string;
      status: UserStatus;
      profile?: {
        __typename?: "Profile";
        id: string;
        userId: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }> | null;
  };
};

export type RedeemPromoCodeMutationVariables = Exact<{
  input: RedeemPromoCodeInput;
}>;

export type RedeemPromoCodeMutation = {
  __typename?: "Mutation";
  promoCode: {
    __typename?: "PromoCodeMutation";
    redeemPromoCode: {
      __typename?: "Transaction";
      id: string;
      purchaseDate: Date;
      expirationDate: Date;
      accessType: AppAccessType;
      promotionalCode?: string | null;
      tourId?: string | null;
    };
  };
};

export type CreateStoryByGptMutationVariables = Exact<{
  input: CreateStoryByGptInput;
}>;

export type CreateStoryByGptMutation = {
  __typename?: "Mutation";
  story: { __typename?: "StoryMutation"; createStoryByGpt: boolean };
};

export type StoryCreationRequestUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type StoryCreationRequestUpdatedSubscription = {
  __typename?: "Subscription";
  storyCreationRequestUpdated: {
    __typename?: "StoryCreationRequest";
    id: string;
    status: StoryCreationRequestStatus;
    userStory?: {
      __typename?: "UserStory";
      id: string;
      datoStoryId: string;
      userTourStopId?: string | null;
      userStorySlides?: Array<{
        __typename?: "UserStorySlide";
        id: string;
        datoStorySlideId: string;
        userStoryId: string;
        userQuiz?: {
          __typename?: "UserQuiz";
          id: string;
          userStorySlideId?: string | null;
          isCompleted?: boolean | null;
          isBlocking?: boolean | null;
          userQuizTasks?: Array<{
            __typename?: "UserQuizTask";
            id: string;
            userQuizId: string;
            datoQuizTaskId: string;
            quizTaskType: QuizTaskType;
            isCompleted?: boolean | null;
            isStarted?: boolean | null;
            answerId?: string | null;
            answerText?: string | null;
            answerLocation?: string | null;
            isCorrectAnswer?: boolean | null;
          }> | null;
        } | null;
      }> | null;
    } | null;
  };
};

export type EvaluateStoryMutationVariables = Exact<{
  input: EvaluateStoryInput;
}>;

export type EvaluateStoryMutation = {
  __typename?: "Mutation";
  storyEvaluation: {
    __typename?: "StoryEvaluationMutation";
    evaluateStory: {
      __typename?: "StoryEvaluation";
      id: string;
      evaluationType: EvaluationType;
    };
  };
};

export type GetMyStoryEvaluationQueryVariables = Exact<{
  input: GetUserStoryEvaluationInput;
}>;

export type GetMyStoryEvaluationQuery = {
  __typename?: "Query";
  storyEvaluation: {
    __typename?: "StoryEvaluationQuery";
    getMyStoryEvaluation?: {
      __typename?: "StoryEvaluation";
      id: string;
      evaluationType: EvaluationType;
    } | null;
  };
};

export type GetStoriesLikeStatisticsQueryVariables = Exact<{
  input?: InputMaybe<GetStoriesLikeStatisticsInput>;
}>;

export type GetStoriesLikeStatisticsQuery = {
  __typename?: "Query";
  storyEvaluation: {
    __typename?: "StoryEvaluationQuery";
    getStoriesLikeStatistics: Array<{
      __typename?: "StoryLikeStatistics";
      datoStoryId: string;
      totalLikesAmount?: number | null;
    }>;
  };
};

export type GetStoryLikeStatisticsQueryVariables = Exact<{
  input: GetStoryLikeStatisticsInput;
}>;

export type GetStoryLikeStatisticsQuery = {
  __typename?: "Query";
  storyEvaluation: {
    __typename?: "StoryEvaluationQuery";
    getStoryLikeStatistics?: {
      __typename?: "StoryLikeStatistics";
      datoStoryId: string;
      totalLikesAmount?: number | null;
    } | null;
  };
};

export type CreateTourByGptMutationVariables = Exact<{
  input: CreateTourByGptInput;
}>;

export type CreateTourByGptMutation = {
  __typename?: "Mutation";
  tour: { __typename?: "TourMutation"; createTourByGpt: boolean };
};

export type GenerateTourAudioMutationVariables = Exact<{
  input: GenerateTourAudioInput;
}>;

export type GenerateTourAudioMutation = {
  __typename?: "Mutation";
  tour: {
    __typename?: "TourMutation";
    generateTourAudio: {
      __typename?: "TourAudioGenerationRequest";
      id: string;
      status: TourAudioGenerationRequestStatus;
      datoTourId: string;
    };
  };
};

export type TourAudioGenerationRequestUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type TourAudioGenerationRequestUpdatedSubscription = {
  __typename?: "Subscription";
  tourAudioGenerationRequestUpdated: {
    __typename?: "TourAudioGenerationRequest";
    id: string;
    status: TourAudioGenerationRequestStatus;
    datoTourId: string;
  };
};

export type GetTourCreationRequestStatisticsByUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTourCreationRequestStatisticsByUserQuery = {
  __typename?: "Query";
  tourCreationRequest: {
    __typename?: "TourCreationRequestQuery";
    getTourCreationRequestStatisticsByUser: {
      __typename?: "TourCreationRequestStatisticsByUser";
      userId: string;
      total: number;
      success: number;
      inProgress: number;
    };
  };
};

export type TourCreationRequestUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type TourCreationRequestUpdatedSubscription = {
  __typename?: "Subscription";
  tourCreationRequestUpdated: {
    __typename?: "TourCreationRequest";
    id: string;
    status: TourCreationRequestStatus;
    userTour?: {
      __typename?: "UserTour";
      id: string;
      datoTourId: string;
      userTourStops?: Array<{
        __typename?: "UserTourStop";
        id: string;
        datoTourStopId: string;
        userTourId: string;
        userStories?: Array<{
          __typename?: "UserStory";
          id: string;
          datoStoryId: string;
          userTourStopId?: string | null;
          userStorySlides?: Array<{
            __typename?: "UserStorySlide";
            id: string;
            datoStorySlideId: string;
            userStoryId: string;
            userQuiz?: {
              __typename?: "UserQuiz";
              id: string;
              userStorySlideId?: string | null;
              isCompleted?: boolean | null;
              isBlocking?: boolean | null;
              userQuizTasks?: Array<{
                __typename?: "UserQuizTask";
                id: string;
                userQuizId: string;
                datoQuizTaskId: string;
                quizTaskType: QuizTaskType;
                isCompleted?: boolean | null;
                isStarted?: boolean | null;
                answerId?: string | null;
                answerText?: string | null;
                answerLocation?: string | null;
                isCorrectAnswer?: boolean | null;
              }> | null;
            } | null;
          }> | null;
        }> | null;
      }> | null;
    } | null;
  };
};

export type GetTourRatingStatisticsQueryVariables = Exact<{
  input: GetTourRatingStatisticsInput;
}>;

export type GetTourRatingStatisticsQuery = {
  __typename?: "Query";
  tourReview: {
    __typename?: "TourReviewQuery";
    getTourRatingStatistics?: {
      __typename?: "TourRatingStatistics";
      datoTourId: string;
      averageRating?: number | null;
      totalReviewsAmount?: number | null;
    } | null;
  };
};

export type GetToursRatingStatisticsQueryVariables = Exact<{
  input?: InputMaybe<GetToursRatingStatisticsInput>;
}>;

export type GetToursRatingStatisticsQuery = {
  __typename?: "Query";
  tourReview: {
    __typename?: "TourReviewQuery";
    getToursRatingStatistics: Array<{
      __typename?: "TourRatingStatistics";
      datoTourId: string;
      averageRating?: number | null;
      totalReviewsAmount?: number | null;
    }>;
  };
};

export type GetActiveTransactionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetActiveTransactionsQuery = {
  __typename?: "Query";
  transaction: {
    __typename?: "TransactionQuery";
    getActiveTransactions: Array<{
      __typename?: "Transaction";
      id: string;
      purchaseDate: Date;
      expirationDate: Date;
      accessType: AppAccessType;
      promotionalCode?: string | null;
      tourId?: string | null;
    }>;
  };
};

export type TransactionCreatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type TransactionCreatedSubscription = {
  __typename?: "Subscription";
  transactionCreated: {
    __typename?: "Transaction";
    id: string;
    purchaseDate: Date;
    expirationDate: Date;
    accessType: AppAccessType;
    promotionalCode?: string | null;
    tourId?: string | null;
  };
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  user: { __typename?: "UserMutation"; changePassword: boolean };
};

export type DeleteMeMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteMeMutation = {
  __typename?: "Mutation";
  user: { __typename?: "UserMutation"; deleteMe: boolean };
};

export type DeleteMeConfirmMutationVariables = Exact<{
  input: DeleteUserConfirmInput;
}>;

export type DeleteMeConfirmMutation = {
  __typename?: "Mutation";
  user: { __typename?: "UserMutation"; deleteMeConfirm: boolean };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  user: {
    __typename?: "UserQuery";
    me?: {
      __typename?: "User";
      id: string;
      email: string;
      token?: string | null;
      status: UserStatus;
      profile?: {
        __typename?: "Profile";
        id: string;
        userId: string;
        firstName?: string | null;
        lastName?: string | null;
        birthday?: Date | null;
        datoGenderId?: string | null;
        datoVoiceId?: string | null;
      } | null;
      groupSharing?: {
        __typename?: "GroupSharing";
        id: string;
        navigationUrl?: string | null;
        isPlaying?: boolean | null;
        startAudioTime?: Date | null;
        stopAudioTime?: Date | null;
        playbackRate?: number | null;
        users?: Array<{
          __typename?: "User";
          id: string;
          email: string;
          status: UserStatus;
          profile?: {
            __typename?: "Profile";
            id: string;
            userId: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }> | null;
      } | null;
    } | null;
  };
};

export type ResendDeleteMeVerificationEmailMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendDeleteMeVerificationEmailMutation = {
  __typename?: "Mutation";
  user: {
    __typename?: "UserMutation";
    resendDeleteMeVerificationEmail: boolean;
  };
};

export type SetOneSignalSubscriptionIdMutationVariables = Exact<{
  input: SetOneSignalSubscriptionIdInput;
}>;

export type SetOneSignalSubscriptionIdMutation = {
  __typename?: "Mutation";
  user: { __typename?: "UserMutation"; setOneSignalSubscriptionId: boolean };
};

export type UpdateMeMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateMeMutation = {
  __typename?: "Mutation";
  user: {
    __typename?: "UserMutation";
    updateMe: {
      __typename?: "User";
      id: string;
      email: string;
      token?: string | null;
      status: UserStatus;
      profile?: {
        __typename?: "Profile";
        id: string;
        userId: string;
        firstName?: string | null;
        lastName?: string | null;
        birthday?: Date | null;
        datoGenderId?: string | null;
        datoVoiceId?: string | null;
      } | null;
      groupSharing?: {
        __typename?: "GroupSharing";
        id: string;
        navigationUrl?: string | null;
        isPlaying?: boolean | null;
        startAudioTime?: Date | null;
        stopAudioTime?: Date | null;
        playbackRate?: number | null;
        users?: Array<{
          __typename?: "User";
          id: string;
          email: string;
          status: UserStatus;
          profile?: {
            __typename?: "Profile";
            id: string;
            userId: string;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }> | null;
      } | null;
    };
  };
};

export type GetUserQuizTaskByWithParentsQueryVariables = Exact<{
  input: GetUserQuizTaskByInput;
}>;

export type GetUserQuizTaskByWithParentsQuery = {
  __typename?: "Query";
  userQuizTask: {
    __typename?: "UserQuizTaskQuery";
    getUserQuizTaskBy?: {
      __typename?: "UserQuizTask";
      id: string;
      userQuizId: string;
      datoQuizTaskId: string;
      quizTaskType: QuizTaskType;
      isCompleted?: boolean | null;
      isStarted?: boolean | null;
      answerId?: string | null;
      answerText?: string | null;
      answerLocation?: string | null;
      isCorrectAnswer?: boolean | null;
      userQuiz: {
        __typename?: "UserQuiz";
        userStorySlide?: {
          __typename?: "UserStorySlide";
          datoStorySlideId: string;
          userStory: {
            __typename?: "UserStory";
            datoStoryId: string;
            userTourStop?: {
              __typename?: "UserTourStop";
              datoTourStopId: string;
              userTour: { __typename?: "UserTour"; datoTourId: string };
            } | null;
          };
        } | null;
      };
    } | null;
  };
};

export type UpdateUserQuizTaskMutationVariables = Exact<{
  input: UpdateUserQuizTaskInput;
}>;

export type UpdateUserQuizTaskMutation = {
  __typename?: "Mutation";
  userQuizTask: {
    __typename?: "UserQuizTaskMutation";
    updateUserQuizTask: {
      __typename?: "UserQuizTask";
      id: string;
      userQuizId: string;
      datoQuizTaskId: string;
      quizTaskType: QuizTaskType;
      isCompleted?: boolean | null;
      isStarted?: boolean | null;
      answerId?: string | null;
      answerText?: string | null;
      answerLocation?: string | null;
      isCorrectAnswer?: boolean | null;
    };
  };
};

export type UpdateUserQuizTaskAndGetParentsMutationVariables = Exact<{
  input: UpdateUserQuizTaskInput;
}>;

export type UpdateUserQuizTaskAndGetParentsMutation = {
  __typename?: "Mutation";
  userQuizTask: {
    __typename?: "UserQuizTaskMutation";
    updateUserQuizTask: {
      __typename?: "UserQuizTask";
      id: string;
      userQuizId: string;
      datoQuizTaskId: string;
      quizTaskType: QuizTaskType;
      isCompleted?: boolean | null;
      isStarted?: boolean | null;
      answerId?: string | null;
      answerText?: string | null;
      answerLocation?: string | null;
      isCorrectAnswer?: boolean | null;
      userQuiz: {
        __typename?: "UserQuiz";
        userStorySlide?: {
          __typename?: "UserStorySlide";
          datoStorySlideId: string;
          userStory: {
            __typename?: "UserStory";
            datoStoryId: string;
            userTourStop?: {
              __typename?: "UserTourStop";
              datoTourStopId: string;
              userTour: { __typename?: "UserTour"; datoTourId: string };
            } | null;
          };
        } | null;
      };
    };
  };
};

export type CreateUserStoryMutationVariables = Exact<{
  input: CreateUserStoryInput;
}>;

export type CreateUserStoryMutation = {
  __typename?: "Mutation";
  userStory: {
    __typename?: "UserStoryMutation";
    createUserStory: {
      __typename?: "UserStory";
      id: string;
      datoStoryId: string;
      userTourStopId?: string | null;
      userStorySlides?: Array<{
        __typename?: "UserStorySlide";
        id: string;
        datoStorySlideId: string;
        userStoryId: string;
        userQuiz?: {
          __typename?: "UserQuiz";
          id: string;
          userStorySlideId?: string | null;
          isCompleted?: boolean | null;
          isBlocking?: boolean | null;
          userQuizTasks?: Array<{
            __typename?: "UserQuizTask";
            id: string;
            userQuizId: string;
            datoQuizTaskId: string;
            quizTaskType: QuizTaskType;
            isCompleted?: boolean | null;
            isStarted?: boolean | null;
            answerId?: string | null;
            answerText?: string | null;
            answerLocation?: string | null;
            isCorrectAnswer?: boolean | null;
          }> | null;
        } | null;
      }> | null;
    };
  };
};

export type GetUserStoriesCreatedByMeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserStoriesCreatedByMeQuery = {
  __typename?: "Query";
  userStory: {
    __typename?: "UserStoryQuery";
    getUserStoriesCreatedByMe: Array<{
      __typename?: "UserStory";
      id: string;
      datoStoryId: string;
    }>;
  };
};

export type GetViewedUserStoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetViewedUserStoriesQuery = {
  __typename?: "Query";
  userStory: {
    __typename?: "UserStoryQuery";
    getViewedUserStories: Array<{
      __typename?: "UserStory";
      id: string;
      datoStoryId: string;
    }>;
  };
};

export type UpdateUserStorySlideMutationVariables = Exact<{
  input: UpdateUserStorySlideInput;
}>;

export type UpdateUserStorySlideMutation = {
  __typename?: "Mutation";
  userStorySlide: {
    __typename?: "UserStorySlideMutation";
    updateUserStorySlide: {
      __typename?: "UserStorySlide";
      id: string;
      userStory: { __typename?: "UserStory"; id: string; datoStoryId: string };
    };
  };
};

export type CreateUserStorySlideAudioProgressMutationVariables = Exact<{
  input: CreateUserStorySlideAudioProgressInput;
}>;

export type CreateUserStorySlideAudioProgressMutation = {
  __typename?: "Mutation";
  userStorySlideAudioProgress: {
    __typename?: "UserStorySlideAudioProgressMutation";
    createUserStorySlideAudioProgress: {
      __typename?: "UserStorySlideAudioProgress";
      id: string;
    };
  };
};

export type CreateUserTourMutationVariables = Exact<{
  input: CreateUserTourInput;
}>;

export type CreateUserTourMutation = {
  __typename?: "Mutation";
  userTour: {
    __typename?: "UserTourMutation";
    createUserTour: {
      __typename?: "UserTour";
      id: string;
      datoTourId: string;
      userTourStops?: Array<{
        __typename?: "UserTourStop";
        id: string;
        datoTourStopId: string;
        userTourId: string;
        userStories?: Array<{
          __typename?: "UserStory";
          id: string;
          datoStoryId: string;
          userTourStopId?: string | null;
          userStorySlides?: Array<{
            __typename?: "UserStorySlide";
            id: string;
            datoStorySlideId: string;
            userStoryId: string;
            userQuiz?: {
              __typename?: "UserQuiz";
              id: string;
              userStorySlideId?: string | null;
              isCompleted?: boolean | null;
              isBlocking?: boolean | null;
              userQuizTasks?: Array<{
                __typename?: "UserQuizTask";
                id: string;
                userQuizId: string;
                datoQuizTaskId: string;
                quizTaskType: QuizTaskType;
                isCompleted?: boolean | null;
                isStarted?: boolean | null;
                answerId?: string | null;
                answerText?: string | null;
                answerLocation?: string | null;
                isCorrectAnswer?: boolean | null;
              }> | null;
            } | null;
          }> | null;
        }> | null;
      }> | null;
    };
  };
};

export type GetUserTourQueryVariables = Exact<{
  input: GetUserTourInput;
}>;

export type GetUserTourQuery = {
  __typename?: "Query";
  userTour: {
    __typename?: "UserTourQuery";
    getUserTour?: {
      __typename?: "UserTour";
      id: string;
      datoTourId: string;
      userTourStops?: Array<{
        __typename?: "UserTourStop";
        id: string;
        datoTourStopId: string;
        userTourId: string;
        userStories?: Array<{
          __typename?: "UserStory";
          id: string;
          datoStoryId: string;
          userTourStopId?: string | null;
          userStorySlides?: Array<{
            __typename?: "UserStorySlide";
            id: string;
            datoStorySlideId: string;
            userStoryId: string;
            userQuiz?: {
              __typename?: "UserQuiz";
              id: string;
              userStorySlideId?: string | null;
              isCompleted?: boolean | null;
              isBlocking?: boolean | null;
              userQuizTasks?: Array<{
                __typename?: "UserQuizTask";
                id: string;
                userQuizId: string;
                datoQuizTaskId: string;
                quizTaskType: QuizTaskType;
                isCompleted?: boolean | null;
                isStarted?: boolean | null;
                answerId?: string | null;
                answerText?: string | null;
                answerLocation?: string | null;
                isCorrectAnswer?: boolean | null;
              }> | null;
            } | null;
          }> | null;
        }> | null;
      }> | null;
    } | null;
  };
};

export type GetUserTourAudioStatisticsQueryVariables = Exact<{
  input: GetUserTourAudioStatisticsInput;
}>;

export type GetUserTourAudioStatisticsQuery = {
  __typename?: "Query";
  userTour: {
    __typename?: "UserTourQuery";
    getUserTourAudioStatistics?: {
      __typename?: "UserTourAudioStatistics";
      datoTourId: string;
      audioProgress?: number | null;
      userTourStopsAudioStatistics?: Array<{
        __typename?: "UserTourStopAudioStatistics";
        datoTourStopId: string;
        audioProgress?: number | null;
      }> | null;
    } | null;
  };
};

export type GetUserToursAudioStatisticsQueryVariables = Exact<{
  input?: InputMaybe<GetUserToursAudioStatisticsInput>;
}>;

export type GetUserToursAudioStatisticsQuery = {
  __typename?: "Query";
  userTour: {
    __typename?: "UserTourQuery";
    getUserToursAudioStatistics?: Array<{
      __typename?: "UserTourAudioStatistics";
      datoTourId: string;
      audioProgress?: number | null;
    }> | null;
  };
};

export type GetUserToursCreatedByMeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserToursCreatedByMeQuery = {
  __typename?: "Query";
  userTour: {
    __typename?: "UserTourQuery";
    getUserToursCreatedByMe: Array<{
      __typename?: "UserTour";
      id: string;
      datoTourId: string;
    }>;
  };
};

export type ResetUserTourMutationVariables = Exact<{
  input: ResetUserTourInput;
}>;

export type ResetUserTourMutation = {
  __typename?: "Mutation";
  userTour: {
    __typename?: "UserTourMutation";
    resetUserTour: { __typename?: "UserTour"; id: string };
  };
};

export type GroupSharingFieldsFragment = {
  __typename?: "GroupSharing";
  id: string;
  navigationUrl?: string | null;
  isPlaying?: boolean | null;
  startAudioTime?: Date | null;
  stopAudioTime?: Date | null;
  playbackRate?: number | null;
  users?: Array<{
    __typename?: "User";
    id: string;
    email: string;
    status: UserStatus;
    profile?: {
      __typename?: "Profile";
      id: string;
      userId: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  }> | null;
};

export type GroupSharingUserFieldsFragment = {
  __typename?: "User";
  id: string;
  email: string;
  status: UserStatus;
  profile?: {
    __typename?: "Profile";
    id: string;
    userId: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type StoryEvaluationFieldsFragment = {
  __typename?: "StoryEvaluation";
  id: string;
  evaluationType: EvaluationType;
};

export type SupportedAppVersionFieldsFragment = {
  __typename?: "SupportedAppVersion";
  android?: string | null;
  ios?: string | null;
};

export type TourRatingStatisticsFieldsFragment = {
  __typename?: "TourRatingStatistics";
  datoTourId: string;
  averageRating?: number | null;
  totalReviewsAmount?: number | null;
};

export type TransactionFieldsFragment = {
  __typename?: "Transaction";
  id: string;
  purchaseDate: Date;
  expirationDate: Date;
  accessType: AppAccessType;
  promotionalCode?: string | null;
  tourId?: string | null;
};

export type UserFieldsFragment = {
  __typename?: "User";
  id: string;
  email: string;
  token?: string | null;
  status: UserStatus;
  profile?: {
    __typename?: "Profile";
    id: string;
    userId: string;
    firstName?: string | null;
    lastName?: string | null;
    birthday?: Date | null;
    datoGenderId?: string | null;
    datoVoiceId?: string | null;
  } | null;
  groupSharing?: {
    __typename?: "GroupSharing";
    id: string;
    navigationUrl?: string | null;
    isPlaying?: boolean | null;
    startAudioTime?: Date | null;
    stopAudioTime?: Date | null;
    playbackRate?: number | null;
    users?: Array<{
      __typename?: "User";
      id: string;
      email: string;
      status: UserStatus;
      profile?: {
        __typename?: "Profile";
        id: string;
        userId: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type UserQuizFieldsFragment = {
  __typename?: "UserQuiz";
  id: string;
  userStorySlideId?: string | null;
  isCompleted?: boolean | null;
  isBlocking?: boolean | null;
  userQuizTasks?: Array<{
    __typename?: "UserQuizTask";
    id: string;
    userQuizId: string;
    datoQuizTaskId: string;
    quizTaskType: QuizTaskType;
    isCompleted?: boolean | null;
    isStarted?: boolean | null;
    answerId?: string | null;
    answerText?: string | null;
    answerLocation?: string | null;
    isCorrectAnswer?: boolean | null;
  }> | null;
};

export type UserQuizTaskFieldsFragment = {
  __typename?: "UserQuizTask";
  id: string;
  userQuizId: string;
  datoQuizTaskId: string;
  quizTaskType: QuizTaskType;
  isCompleted?: boolean | null;
  isStarted?: boolean | null;
  answerId?: string | null;
  answerText?: string | null;
  answerLocation?: string | null;
  isCorrectAnswer?: boolean | null;
};

export type UserQuizTaskParentFieldsFragment = {
  __typename?: "UserQuizTask";
  userQuiz: {
    __typename?: "UserQuiz";
    userStorySlide?: {
      __typename?: "UserStorySlide";
      datoStorySlideId: string;
      userStory: {
        __typename?: "UserStory";
        datoStoryId: string;
        userTourStop?: {
          __typename?: "UserTourStop";
          datoTourStopId: string;
          userTour: { __typename?: "UserTour"; datoTourId: string };
        } | null;
      };
    } | null;
  };
};

export type UserStoryFieldsFragment = {
  __typename?: "UserStory";
  id: string;
  datoStoryId: string;
  userTourStopId?: string | null;
  userStorySlides?: Array<{
    __typename?: "UserStorySlide";
    id: string;
    datoStorySlideId: string;
    userStoryId: string;
    userQuiz?: {
      __typename?: "UserQuiz";
      id: string;
      userStorySlideId?: string | null;
      isCompleted?: boolean | null;
      isBlocking?: boolean | null;
      userQuizTasks?: Array<{
        __typename?: "UserQuizTask";
        id: string;
        userQuizId: string;
        datoQuizTaskId: string;
        quizTaskType: QuizTaskType;
        isCompleted?: boolean | null;
        isStarted?: boolean | null;
        answerId?: string | null;
        answerText?: string | null;
        answerLocation?: string | null;
        isCorrectAnswer?: boolean | null;
      }> | null;
    } | null;
  }> | null;
};

export type UserStorySlideFieldsFragment = {
  __typename?: "UserStorySlide";
  id: string;
  datoStorySlideId: string;
  userStoryId: string;
  userQuiz?: {
    __typename?: "UserQuiz";
    id: string;
    userStorySlideId?: string | null;
    isCompleted?: boolean | null;
    isBlocking?: boolean | null;
    userQuizTasks?: Array<{
      __typename?: "UserQuizTask";
      id: string;
      userQuizId: string;
      datoQuizTaskId: string;
      quizTaskType: QuizTaskType;
      isCompleted?: boolean | null;
      isStarted?: boolean | null;
      answerId?: string | null;
      answerText?: string | null;
      answerLocation?: string | null;
      isCorrectAnswer?: boolean | null;
    }> | null;
  } | null;
};

export type UserTourFieldsFragment = {
  __typename?: "UserTour";
  id: string;
  datoTourId: string;
  userTourStops?: Array<{
    __typename?: "UserTourStop";
    id: string;
    datoTourStopId: string;
    userTourId: string;
    userStories?: Array<{
      __typename?: "UserStory";
      id: string;
      datoStoryId: string;
      userTourStopId?: string | null;
      userStorySlides?: Array<{
        __typename?: "UserStorySlide";
        id: string;
        datoStorySlideId: string;
        userStoryId: string;
        userQuiz?: {
          __typename?: "UserQuiz";
          id: string;
          userStorySlideId?: string | null;
          isCompleted?: boolean | null;
          isBlocking?: boolean | null;
          userQuizTasks?: Array<{
            __typename?: "UserQuizTask";
            id: string;
            userQuizId: string;
            datoQuizTaskId: string;
            quizTaskType: QuizTaskType;
            isCompleted?: boolean | null;
            isStarted?: boolean | null;
            answerId?: string | null;
            answerText?: string | null;
            answerLocation?: string | null;
            isCorrectAnswer?: boolean | null;
          }> | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type UserTourStopFieldsFragment = {
  __typename?: "UserTourStop";
  id: string;
  datoTourStopId: string;
  userTourId: string;
  userStories?: Array<{
    __typename?: "UserStory";
    id: string;
    datoStoryId: string;
    userTourStopId?: string | null;
    userStorySlides?: Array<{
      __typename?: "UserStorySlide";
      id: string;
      datoStorySlideId: string;
      userStoryId: string;
      userQuiz?: {
        __typename?: "UserQuiz";
        id: string;
        userStorySlideId?: string | null;
        isCompleted?: boolean | null;
        isBlocking?: boolean | null;
        userQuizTasks?: Array<{
          __typename?: "UserQuizTask";
          id: string;
          userQuizId: string;
          datoQuizTaskId: string;
          quizTaskType: QuizTaskType;
          isCompleted?: boolean | null;
          isStarted?: boolean | null;
          answerId?: string | null;
          answerText?: string | null;
          answerLocation?: string | null;
          isCorrectAnswer?: boolean | null;
        }> | null;
      } | null;
    }> | null;
  }> | null;
};

export const StoryEvaluationFieldsFragmentDoc = gql`
  fragment StoryEvaluationFields on StoryEvaluation {
    id
    evaluationType
  }
`;
export const SupportedAppVersionFieldsFragmentDoc = gql`
  fragment SupportedAppVersionFields on SupportedAppVersion {
    android
    ios
  }
`;
export const TourRatingStatisticsFieldsFragmentDoc = gql`
  fragment TourRatingStatisticsFields on TourRatingStatistics {
    datoTourId
    averageRating
    totalReviewsAmount
  }
`;
export const TransactionFieldsFragmentDoc = gql`
  fragment TransactionFields on Transaction {
    id
    purchaseDate
    expirationDate
    accessType
    promotionalCode
    tourId
  }
`;
export const GroupSharingUserFieldsFragmentDoc = gql`
  fragment GroupSharingUserFields on User {
    id
    email
    status
    profile {
      id
      userId
      firstName
      lastName
    }
  }
`;
export const GroupSharingFieldsFragmentDoc = gql`
  fragment GroupSharingFields on GroupSharing {
    id
    navigationUrl
    isPlaying
    startAudioTime
    stopAudioTime
    playbackRate
    users {
      ...GroupSharingUserFields
    }
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    email
    token
    status
    profile {
      id
      userId
      firstName
      lastName
      birthday
      datoGenderId
      datoVoiceId
    }
    groupSharing {
      ...GroupSharingFields
    }
  }
`;
export const UserQuizTaskParentFieldsFragmentDoc = gql`
  fragment UserQuizTaskParentFields on UserQuizTask {
    userQuiz {
      userStorySlide {
        datoStorySlideId
        userStory {
          datoStoryId
          userTourStop {
            datoTourStopId
            userTour {
              datoTourId
            }
          }
        }
      }
    }
  }
`;
export const UserQuizTaskFieldsFragmentDoc = gql`
  fragment UserQuizTaskFields on UserQuizTask {
    id
    userQuizId
    datoQuizTaskId
    quizTaskType
    isCompleted
    isStarted
    answerId
    answerText
    answerLocation
    isCorrectAnswer
    userQuizId
  }
`;
export const UserQuizFieldsFragmentDoc = gql`
  fragment UserQuizFields on UserQuiz {
    id
    userStorySlideId
    isCompleted
    isBlocking
    userQuizTasks {
      ...UserQuizTaskFields
    }
  }
`;
export const UserStorySlideFieldsFragmentDoc = gql`
  fragment UserStorySlideFields on UserStorySlide {
    id
    datoStorySlideId
    userStoryId
    userQuiz {
      ...UserQuizFields
    }
  }
`;
export const UserStoryFieldsFragmentDoc = gql`
  fragment UserStoryFields on UserStory {
    id
    datoStoryId
    userTourStopId
    userStorySlides {
      ...UserStorySlideFields
    }
  }
`;
export const UserTourStopFieldsFragmentDoc = gql`
  fragment UserTourStopFields on UserTourStop {
    id
    datoTourStopId
    userTourId
    userStories {
      ...UserStoryFields
    }
  }
`;
export const UserTourFieldsFragmentDoc = gql`
  fragment UserTourFields on UserTour {
    id
    datoTourId
    userTourStops {
      ...UserTourStopFields
    }
  }
`;
export const GetSupportedAppVersionDocument = gql`
  query GetSupportedAppVersion {
    applicationSettings {
      getSupportedAppVersion {
        ...SupportedAppVersionFields
      }
    }
  }
  ${SupportedAppVersionFieldsFragmentDoc}
`;

/**
 * __useGetSupportedAppVersionQuery__
 *
 * To run a query within a React component, call `useGetSupportedAppVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedAppVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedAppVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportedAppVersionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSupportedAppVersionQuery,
    GetSupportedAppVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSupportedAppVersionQuery,
    GetSupportedAppVersionQueryVariables
  >(GetSupportedAppVersionDocument, options);
}
export function useGetSupportedAppVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportedAppVersionQuery,
    GetSupportedAppVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupportedAppVersionQuery,
    GetSupportedAppVersionQueryVariables
  >(GetSupportedAppVersionDocument, options);
}
export type GetSupportedAppVersionQueryHookResult = ReturnType<
  typeof useGetSupportedAppVersionQuery
>;
export type GetSupportedAppVersionLazyQueryHookResult = ReturnType<
  typeof useGetSupportedAppVersionLazyQuery
>;
export type GetSupportedAppVersionQueryResult = Apollo.QueryResult<
  GetSupportedAppVersionQuery,
  GetSupportedAppVersionQueryVariables
>;
export const SupportedAppVersionChangedDocument = gql`
  subscription SupportedAppVersionChanged {
    supportedAppVersionChanged {
      ...SupportedAppVersionFields
    }
  }
  ${SupportedAppVersionFieldsFragmentDoc}
`;

/**
 * __useSupportedAppVersionChangedSubscription__
 *
 * To run a query within a React component, call `useSupportedAppVersionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSupportedAppVersionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportedAppVersionChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSupportedAppVersionChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SupportedAppVersionChangedSubscription,
    SupportedAppVersionChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SupportedAppVersionChangedSubscription,
    SupportedAppVersionChangedSubscriptionVariables
  >(SupportedAppVersionChangedDocument, options);
}
export type SupportedAppVersionChangedSubscriptionHookResult = ReturnType<
  typeof useSupportedAppVersionChangedSubscription
>;
export type SupportedAppVersionChangedSubscriptionResult =
  Apollo.SubscriptionResult<SupportedAppVersionChangedSubscription>;
export const LogOutDocument = gql`
  mutation LogOut {
    auth {
      logOut
    }
  }
`;
export type LogOutMutationFn = Apollo.MutationFunction<
  LogOutMutation,
  LogOutMutationVariables
>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogOutMutation,
    LogOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(
    LogOutDocument,
    options
  );
}
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<
  LogOutMutation,
  LogOutMutationVariables
>;
export const ResendResetPasswordVerificationEmailDocument = gql`
  mutation ResendResetPasswordVerificationEmail(
    $input: ResendResetPasswordVerificationEmailInput!
  ) {
    auth {
      resendResetPasswordVerificationEmail(input: $input)
    }
  }
`;
export type ResendResetPasswordVerificationEmailMutationFn =
  Apollo.MutationFunction<
    ResendResetPasswordVerificationEmailMutation,
    ResendResetPasswordVerificationEmailMutationVariables
  >;

/**
 * __useResendResetPasswordVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendResetPasswordVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendResetPasswordVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendResetPasswordVerificationEmailMutation, { data, loading, error }] = useResendResetPasswordVerificationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendResetPasswordVerificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendResetPasswordVerificationEmailMutation,
    ResendResetPasswordVerificationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendResetPasswordVerificationEmailMutation,
    ResendResetPasswordVerificationEmailMutationVariables
  >(ResendResetPasswordVerificationEmailDocument, options);
}
export type ResendResetPasswordVerificationEmailMutationHookResult = ReturnType<
  typeof useResendResetPasswordVerificationEmailMutation
>;
export type ResendResetPasswordVerificationEmailMutationResult =
  Apollo.MutationResult<ResendResetPasswordVerificationEmailMutation>;
export type ResendResetPasswordVerificationEmailMutationOptions =
  Apollo.BaseMutationOptions<
    ResendResetPasswordVerificationEmailMutation,
    ResendResetPasswordVerificationEmailMutationVariables
  >;
export const ResendSignUpVerificationEmailDocument = gql`
  mutation ResendSignUpVerificationEmail(
    $input: ResendSignUpVerificationEmailInput!
  ) {
    auth {
      resendSignUpVerificationEmail(input: $input)
    }
  }
`;
export type ResendSignUpVerificationEmailMutationFn = Apollo.MutationFunction<
  ResendSignUpVerificationEmailMutation,
  ResendSignUpVerificationEmailMutationVariables
>;

/**
 * __useResendSignUpVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendSignUpVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSignUpVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSignUpVerificationEmailMutation, { data, loading, error }] = useResendSignUpVerificationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendSignUpVerificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendSignUpVerificationEmailMutation,
    ResendSignUpVerificationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendSignUpVerificationEmailMutation,
    ResendSignUpVerificationEmailMutationVariables
  >(ResendSignUpVerificationEmailDocument, options);
}
export type ResendSignUpVerificationEmailMutationHookResult = ReturnType<
  typeof useResendSignUpVerificationEmailMutation
>;
export type ResendSignUpVerificationEmailMutationResult =
  Apollo.MutationResult<ResendSignUpVerificationEmailMutation>;
export type ResendSignUpVerificationEmailMutationOptions =
  Apollo.BaseMutationOptions<
    ResendSignUpVerificationEmailMutation,
    ResendSignUpVerificationEmailMutationVariables
  >;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    auth {
      resetPassword(input: $input)
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResetPasswordConfirmDocument = gql`
  mutation ResetPasswordConfirm($input: ResetPasswordConfirmInput!) {
    auth {
      resetPasswordConfirm(input: $input)
    }
  }
`;
export type ResetPasswordConfirmMutationFn = Apollo.MutationFunction<
  ResetPasswordConfirmMutation,
  ResetPasswordConfirmMutationVariables
>;

/**
 * __useResetPasswordConfirmMutation__
 *
 * To run a mutation, you first call `useResetPasswordConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordConfirmMutation, { data, loading, error }] = useResetPasswordConfirmMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordConfirmMutation,
    ResetPasswordConfirmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordConfirmMutation,
    ResetPasswordConfirmMutationVariables
  >(ResetPasswordConfirmDocument, options);
}
export type ResetPasswordConfirmMutationHookResult = ReturnType<
  typeof useResetPasswordConfirmMutation
>;
export type ResetPasswordConfirmMutationResult =
  Apollo.MutationResult<ResetPasswordConfirmMutation>;
export type ResetPasswordConfirmMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordConfirmMutation,
  ResetPasswordConfirmMutationVariables
>;
export const SignInDocument = gql`
  mutation SignIn($input: UserSignInInput!) {
    auth {
      signIn(input: $input) {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${GroupSharingFieldsFragmentDoc}
  ${GroupSharingUserFieldsFragmentDoc}
`;
export type SignInMutationFn = Apollo.MutationFunction<
  SignInMutation,
  SignInMutationVariables
>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInMutation,
    SignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
    options
  );
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const SignUpDocument = gql`
  mutation SignUp($input: UserSignUpInput!) {
    auth {
      signUp(input: $input)
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const SignUpConfirmDocument = gql`
  mutation SignUpConfirm($input: UserSignUpConfirmInput!) {
    auth {
      signUpConfirm(input: $input) {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${GroupSharingFieldsFragmentDoc}
  ${GroupSharingUserFieldsFragmentDoc}
`;
export type SignUpConfirmMutationFn = Apollo.MutationFunction<
  SignUpConfirmMutation,
  SignUpConfirmMutationVariables
>;

/**
 * __useSignUpConfirmMutation__
 *
 * To run a mutation, you first call `useSignUpConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpConfirmMutation, { data, loading, error }] = useSignUpConfirmMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpConfirmMutation,
    SignUpConfirmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignUpConfirmMutation,
    SignUpConfirmMutationVariables
  >(SignUpConfirmDocument, options);
}
export type SignUpConfirmMutationHookResult = ReturnType<
  typeof useSignUpConfirmMutation
>;
export type SignUpConfirmMutationResult =
  Apollo.MutationResult<SignUpConfirmMutation>;
export type SignUpConfirmMutationOptions = Apollo.BaseMutationOptions<
  SignUpConfirmMutation,
  SignUpConfirmMutationVariables
>;
export const SocialSignInDocument = gql`
  mutation SocialSignIn($input: SocialSignInInput!) {
    auth {
      socialSignIn(input: $input) {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${GroupSharingFieldsFragmentDoc}
  ${GroupSharingUserFieldsFragmentDoc}
`;
export type SocialSignInMutationFn = Apollo.MutationFunction<
  SocialSignInMutation,
  SocialSignInMutationVariables
>;

/**
 * __useSocialSignInMutation__
 *
 * To run a mutation, you first call `useSocialSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialSignInMutation, { data, loading, error }] = useSocialSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSocialSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SocialSignInMutation,
    SocialSignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SocialSignInMutation,
    SocialSignInMutationVariables
  >(SocialSignInDocument, options);
}
export type SocialSignInMutationHookResult = ReturnType<
  typeof useSocialSignInMutation
>;
export type SocialSignInMutationResult =
  Apollo.MutationResult<SocialSignInMutation>;
export type SocialSignInMutationOptions = Apollo.BaseMutationOptions<
  SocialSignInMutation,
  SocialSignInMutationVariables
>;
export const GetNumberOfCitiesWithContentDocument = gql`
  query GetNumberOfCitiesWithContent {
    dato {
      getNumberOfCitiesWithContent
    }
  }
`;

/**
 * __useGetNumberOfCitiesWithContentQuery__
 *
 * To run a query within a React component, call `useGetNumberOfCitiesWithContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNumberOfCitiesWithContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumberOfCitiesWithContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNumberOfCitiesWithContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNumberOfCitiesWithContentQuery,
    GetNumberOfCitiesWithContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNumberOfCitiesWithContentQuery,
    GetNumberOfCitiesWithContentQueryVariables
  >(GetNumberOfCitiesWithContentDocument, options);
}
export function useGetNumberOfCitiesWithContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNumberOfCitiesWithContentQuery,
    GetNumberOfCitiesWithContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNumberOfCitiesWithContentQuery,
    GetNumberOfCitiesWithContentQueryVariables
  >(GetNumberOfCitiesWithContentDocument, options);
}
export type GetNumberOfCitiesWithContentQueryHookResult = ReturnType<
  typeof useGetNumberOfCitiesWithContentQuery
>;
export type GetNumberOfCitiesWithContentLazyQueryHookResult = ReturnType<
  typeof useGetNumberOfCitiesWithContentLazyQuery
>;
export type GetNumberOfCitiesWithContentQueryResult = Apollo.QueryResult<
  GetNumberOfCitiesWithContentQuery,
  GetNumberOfCitiesWithContentQueryVariables
>;
export const AttachMeToGroupSharingDocument = gql`
  mutation AttachMeToGroupSharing($input: AttachMeToGroupSharingInput!) {
    groupSharing {
      attachMeToGroupSharing(input: $input) {
        ...GroupSharingFields
      }
    }
  }
  ${GroupSharingFieldsFragmentDoc}
  ${GroupSharingUserFieldsFragmentDoc}
`;
export type AttachMeToGroupSharingMutationFn = Apollo.MutationFunction<
  AttachMeToGroupSharingMutation,
  AttachMeToGroupSharingMutationVariables
>;

/**
 * __useAttachMeToGroupSharingMutation__
 *
 * To run a mutation, you first call `useAttachMeToGroupSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachMeToGroupSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachMeToGroupSharingMutation, { data, loading, error }] = useAttachMeToGroupSharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachMeToGroupSharingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachMeToGroupSharingMutation,
    AttachMeToGroupSharingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AttachMeToGroupSharingMutation,
    AttachMeToGroupSharingMutationVariables
  >(AttachMeToGroupSharingDocument, options);
}
export type AttachMeToGroupSharingMutationHookResult = ReturnType<
  typeof useAttachMeToGroupSharingMutation
>;
export type AttachMeToGroupSharingMutationResult =
  Apollo.MutationResult<AttachMeToGroupSharingMutation>;
export type AttachMeToGroupSharingMutationOptions = Apollo.BaseMutationOptions<
  AttachMeToGroupSharingMutation,
  AttachMeToGroupSharingMutationVariables
>;
export const ChangeGroupSharingDocument = gql`
  mutation ChangeGroupSharing($input: ChangeGroupSharingInput!) {
    groupSharing {
      changeGroupSharing(input: $input) {
        id
      }
    }
  }
`;
export type ChangeGroupSharingMutationFn = Apollo.MutationFunction<
  ChangeGroupSharingMutation,
  ChangeGroupSharingMutationVariables
>;

/**
 * __useChangeGroupSharingMutation__
 *
 * To run a mutation, you first call `useChangeGroupSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeGroupSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeGroupSharingMutation, { data, loading, error }] = useChangeGroupSharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeGroupSharingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeGroupSharingMutation,
    ChangeGroupSharingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeGroupSharingMutation,
    ChangeGroupSharingMutationVariables
  >(ChangeGroupSharingDocument, options);
}
export type ChangeGroupSharingMutationHookResult = ReturnType<
  typeof useChangeGroupSharingMutation
>;
export type ChangeGroupSharingMutationResult =
  Apollo.MutationResult<ChangeGroupSharingMutation>;
export type ChangeGroupSharingMutationOptions = Apollo.BaseMutationOptions<
  ChangeGroupSharingMutation,
  ChangeGroupSharingMutationVariables
>;
export const CreateGroupSharingDocument = gql`
  mutation CreateGroupSharing($input: CreateGroupSharingInput!) {
    groupSharing {
      createGroupSharing(input: $input) {
        ...GroupSharingFields
      }
    }
  }
  ${GroupSharingFieldsFragmentDoc}
  ${GroupSharingUserFieldsFragmentDoc}
`;
export type CreateGroupSharingMutationFn = Apollo.MutationFunction<
  CreateGroupSharingMutation,
  CreateGroupSharingMutationVariables
>;

/**
 * __useCreateGroupSharingMutation__
 *
 * To run a mutation, you first call `useCreateGroupSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupSharingMutation, { data, loading, error }] = useCreateGroupSharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupSharingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupSharingMutation,
    CreateGroupSharingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGroupSharingMutation,
    CreateGroupSharingMutationVariables
  >(CreateGroupSharingDocument, options);
}
export type CreateGroupSharingMutationHookResult = ReturnType<
  typeof useCreateGroupSharingMutation
>;
export type CreateGroupSharingMutationResult =
  Apollo.MutationResult<CreateGroupSharingMutation>;
export type CreateGroupSharingMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupSharingMutation,
  CreateGroupSharingMutationVariables
>;
export const DetachMeFromGroupSharingDocument = gql`
  mutation DetachMeFromGroupSharing {
    groupSharing {
      detachMeFromGroupSharing
    }
  }
`;
export type DetachMeFromGroupSharingMutationFn = Apollo.MutationFunction<
  DetachMeFromGroupSharingMutation,
  DetachMeFromGroupSharingMutationVariables
>;

/**
 * __useDetachMeFromGroupSharingMutation__
 *
 * To run a mutation, you first call `useDetachMeFromGroupSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachMeFromGroupSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachMeFromGroupSharingMutation, { data, loading, error }] = useDetachMeFromGroupSharingMutation({
 *   variables: {
 *   },
 * });
 */
export function useDetachMeFromGroupSharingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachMeFromGroupSharingMutation,
    DetachMeFromGroupSharingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachMeFromGroupSharingMutation,
    DetachMeFromGroupSharingMutationVariables
  >(DetachMeFromGroupSharingDocument, options);
}
export type DetachMeFromGroupSharingMutationHookResult = ReturnType<
  typeof useDetachMeFromGroupSharingMutation
>;
export type DetachMeFromGroupSharingMutationResult =
  Apollo.MutationResult<DetachMeFromGroupSharingMutation>;
export type DetachMeFromGroupSharingMutationOptions =
  Apollo.BaseMutationOptions<
    DetachMeFromGroupSharingMutation,
    DetachMeFromGroupSharingMutationVariables
  >;
export const GetMyGroupSharingDocument = gql`
  query GetMyGroupSharing {
    groupSharing {
      getMyGroupSharing {
        ...GroupSharingFields
      }
    }
  }
  ${GroupSharingFieldsFragmentDoc}
  ${GroupSharingUserFieldsFragmentDoc}
`;

/**
 * __useGetMyGroupSharingQuery__
 *
 * To run a query within a React component, call `useGetMyGroupSharingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyGroupSharingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyGroupSharingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyGroupSharingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyGroupSharingQuery,
    GetMyGroupSharingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyGroupSharingQuery,
    GetMyGroupSharingQueryVariables
  >(GetMyGroupSharingDocument, options);
}
export function useGetMyGroupSharingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyGroupSharingQuery,
    GetMyGroupSharingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyGroupSharingQuery,
    GetMyGroupSharingQueryVariables
  >(GetMyGroupSharingDocument, options);
}
export type GetMyGroupSharingQueryHookResult = ReturnType<
  typeof useGetMyGroupSharingQuery
>;
export type GetMyGroupSharingLazyQueryHookResult = ReturnType<
  typeof useGetMyGroupSharingLazyQuery
>;
export type GetMyGroupSharingQueryResult = Apollo.QueryResult<
  GetMyGroupSharingQuery,
  GetMyGroupSharingQueryVariables
>;
export const GroupSharingChangedDocument = gql`
  subscription GroupSharingChanged {
    groupSharingChanged {
      ...GroupSharingFields
    }
  }
  ${GroupSharingFieldsFragmentDoc}
  ${GroupSharingUserFieldsFragmentDoc}
`;

/**
 * __useGroupSharingChangedSubscription__
 *
 * To run a query within a React component, call `useGroupSharingChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGroupSharingChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupSharingChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGroupSharingChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GroupSharingChangedSubscription,
    GroupSharingChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GroupSharingChangedSubscription,
    GroupSharingChangedSubscriptionVariables
  >(GroupSharingChangedDocument, options);
}
export type GroupSharingChangedSubscriptionHookResult = ReturnType<
  typeof useGroupSharingChangedSubscription
>;
export type GroupSharingChangedSubscriptionResult =
  Apollo.SubscriptionResult<GroupSharingChangedSubscription>;
export const RedeemPromoCodeDocument = gql`
  mutation RedeemPromoCode($input: RedeemPromoCodeInput!) {
    promoCode {
      redeemPromoCode(input: $input) {
        ...TransactionFields
      }
    }
  }
  ${TransactionFieldsFragmentDoc}
`;
export type RedeemPromoCodeMutationFn = Apollo.MutationFunction<
  RedeemPromoCodeMutation,
  RedeemPromoCodeMutationVariables
>;

/**
 * __useRedeemPromoCodeMutation__
 *
 * To run a mutation, you first call `useRedeemPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPromoCodeMutation, { data, loading, error }] = useRedeemPromoCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedeemPromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedeemPromoCodeMutation,
    RedeemPromoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RedeemPromoCodeMutation,
    RedeemPromoCodeMutationVariables
  >(RedeemPromoCodeDocument, options);
}
export type RedeemPromoCodeMutationHookResult = ReturnType<
  typeof useRedeemPromoCodeMutation
>;
export type RedeemPromoCodeMutationResult =
  Apollo.MutationResult<RedeemPromoCodeMutation>;
export type RedeemPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  RedeemPromoCodeMutation,
  RedeemPromoCodeMutationVariables
>;
export const CreateStoryByGptDocument = gql`
  mutation CreateStoryByGpt($input: CreateStoryByGptInput!) {
    story {
      createStoryByGpt(input: $input)
    }
  }
`;
export type CreateStoryByGptMutationFn = Apollo.MutationFunction<
  CreateStoryByGptMutation,
  CreateStoryByGptMutationVariables
>;

/**
 * __useCreateStoryByGptMutation__
 *
 * To run a mutation, you first call `useCreateStoryByGptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryByGptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryByGptMutation, { data, loading, error }] = useCreateStoryByGptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStoryByGptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStoryByGptMutation,
    CreateStoryByGptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStoryByGptMutation,
    CreateStoryByGptMutationVariables
  >(CreateStoryByGptDocument, options);
}
export type CreateStoryByGptMutationHookResult = ReturnType<
  typeof useCreateStoryByGptMutation
>;
export type CreateStoryByGptMutationResult =
  Apollo.MutationResult<CreateStoryByGptMutation>;
export type CreateStoryByGptMutationOptions = Apollo.BaseMutationOptions<
  CreateStoryByGptMutation,
  CreateStoryByGptMutationVariables
>;
export const StoryCreationRequestUpdatedDocument = gql`
  subscription StoryCreationRequestUpdated {
    storyCreationRequestUpdated {
      id
      status
      userStory {
        ...UserStoryFields
      }
    }
  }
  ${UserStoryFieldsFragmentDoc}
  ${UserStorySlideFieldsFragmentDoc}
  ${UserQuizFieldsFragmentDoc}
  ${UserQuizTaskFieldsFragmentDoc}
`;

/**
 * __useStoryCreationRequestUpdatedSubscription__
 *
 * To run a query within a React component, call `useStoryCreationRequestUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStoryCreationRequestUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryCreationRequestUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useStoryCreationRequestUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    StoryCreationRequestUpdatedSubscription,
    StoryCreationRequestUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    StoryCreationRequestUpdatedSubscription,
    StoryCreationRequestUpdatedSubscriptionVariables
  >(StoryCreationRequestUpdatedDocument, options);
}
export type StoryCreationRequestUpdatedSubscriptionHookResult = ReturnType<
  typeof useStoryCreationRequestUpdatedSubscription
>;
export type StoryCreationRequestUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<StoryCreationRequestUpdatedSubscription>;
export const EvaluateStoryDocument = gql`
  mutation EvaluateStory($input: EvaluateStoryInput!) {
    storyEvaluation {
      evaluateStory(input: $input) {
        ...StoryEvaluationFields
      }
    }
  }
  ${StoryEvaluationFieldsFragmentDoc}
`;
export type EvaluateStoryMutationFn = Apollo.MutationFunction<
  EvaluateStoryMutation,
  EvaluateStoryMutationVariables
>;

/**
 * __useEvaluateStoryMutation__
 *
 * To run a mutation, you first call `useEvaluateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEvaluateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [evaluateStoryMutation, { data, loading, error }] = useEvaluateStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEvaluateStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EvaluateStoryMutation,
    EvaluateStoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EvaluateStoryMutation,
    EvaluateStoryMutationVariables
  >(EvaluateStoryDocument, options);
}
export type EvaluateStoryMutationHookResult = ReturnType<
  typeof useEvaluateStoryMutation
>;
export type EvaluateStoryMutationResult =
  Apollo.MutationResult<EvaluateStoryMutation>;
export type EvaluateStoryMutationOptions = Apollo.BaseMutationOptions<
  EvaluateStoryMutation,
  EvaluateStoryMutationVariables
>;
export const GetMyStoryEvaluationDocument = gql`
  query GetMyStoryEvaluation($input: GetUserStoryEvaluationInput!) {
    storyEvaluation {
      getMyStoryEvaluation(input: $input) {
        ...StoryEvaluationFields
      }
    }
  }
  ${StoryEvaluationFieldsFragmentDoc}
`;

/**
 * __useGetMyStoryEvaluationQuery__
 *
 * To run a query within a React component, call `useGetMyStoryEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyStoryEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyStoryEvaluationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyStoryEvaluationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyStoryEvaluationQuery,
    GetMyStoryEvaluationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyStoryEvaluationQuery,
    GetMyStoryEvaluationQueryVariables
  >(GetMyStoryEvaluationDocument, options);
}
export function useGetMyStoryEvaluationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyStoryEvaluationQuery,
    GetMyStoryEvaluationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyStoryEvaluationQuery,
    GetMyStoryEvaluationQueryVariables
  >(GetMyStoryEvaluationDocument, options);
}
export type GetMyStoryEvaluationQueryHookResult = ReturnType<
  typeof useGetMyStoryEvaluationQuery
>;
export type GetMyStoryEvaluationLazyQueryHookResult = ReturnType<
  typeof useGetMyStoryEvaluationLazyQuery
>;
export type GetMyStoryEvaluationQueryResult = Apollo.QueryResult<
  GetMyStoryEvaluationQuery,
  GetMyStoryEvaluationQueryVariables
>;
export const GetStoriesLikeStatisticsDocument = gql`
  query GetStoriesLikeStatistics($input: GetStoriesLikeStatisticsInput) {
    storyEvaluation {
      getStoriesLikeStatistics(input: $input) {
        datoStoryId
        totalLikesAmount
      }
    }
  }
`;

/**
 * __useGetStoriesLikeStatisticsQuery__
 *
 * To run a query within a React component, call `useGetStoriesLikeStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoriesLikeStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoriesLikeStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoriesLikeStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStoriesLikeStatisticsQuery,
    GetStoriesLikeStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStoriesLikeStatisticsQuery,
    GetStoriesLikeStatisticsQueryVariables
  >(GetStoriesLikeStatisticsDocument, options);
}
export function useGetStoriesLikeStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStoriesLikeStatisticsQuery,
    GetStoriesLikeStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStoriesLikeStatisticsQuery,
    GetStoriesLikeStatisticsQueryVariables
  >(GetStoriesLikeStatisticsDocument, options);
}
export type GetStoriesLikeStatisticsQueryHookResult = ReturnType<
  typeof useGetStoriesLikeStatisticsQuery
>;
export type GetStoriesLikeStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetStoriesLikeStatisticsLazyQuery
>;
export type GetStoriesLikeStatisticsQueryResult = Apollo.QueryResult<
  GetStoriesLikeStatisticsQuery,
  GetStoriesLikeStatisticsQueryVariables
>;
export const GetStoryLikeStatisticsDocument = gql`
  query GetStoryLikeStatistics($input: GetStoryLikeStatisticsInput!) {
    storyEvaluation {
      getStoryLikeStatistics(input: $input) {
        datoStoryId
        totalLikesAmount
      }
    }
  }
`;

/**
 * __useGetStoryLikeStatisticsQuery__
 *
 * To run a query within a React component, call `useGetStoryLikeStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryLikeStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryLikeStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoryLikeStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStoryLikeStatisticsQuery,
    GetStoryLikeStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStoryLikeStatisticsQuery,
    GetStoryLikeStatisticsQueryVariables
  >(GetStoryLikeStatisticsDocument, options);
}
export function useGetStoryLikeStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStoryLikeStatisticsQuery,
    GetStoryLikeStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStoryLikeStatisticsQuery,
    GetStoryLikeStatisticsQueryVariables
  >(GetStoryLikeStatisticsDocument, options);
}
export type GetStoryLikeStatisticsQueryHookResult = ReturnType<
  typeof useGetStoryLikeStatisticsQuery
>;
export type GetStoryLikeStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetStoryLikeStatisticsLazyQuery
>;
export type GetStoryLikeStatisticsQueryResult = Apollo.QueryResult<
  GetStoryLikeStatisticsQuery,
  GetStoryLikeStatisticsQueryVariables
>;
export const CreateTourByGptDocument = gql`
  mutation CreateTourByGpt($input: CreateTourByGptInput!) {
    tour {
      createTourByGpt(input: $input)
    }
  }
`;
export type CreateTourByGptMutationFn = Apollo.MutationFunction<
  CreateTourByGptMutation,
  CreateTourByGptMutationVariables
>;

/**
 * __useCreateTourByGptMutation__
 *
 * To run a mutation, you first call `useCreateTourByGptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTourByGptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTourByGptMutation, { data, loading, error }] = useCreateTourByGptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTourByGptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTourByGptMutation,
    CreateTourByGptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTourByGptMutation,
    CreateTourByGptMutationVariables
  >(CreateTourByGptDocument, options);
}
export type CreateTourByGptMutationHookResult = ReturnType<
  typeof useCreateTourByGptMutation
>;
export type CreateTourByGptMutationResult =
  Apollo.MutationResult<CreateTourByGptMutation>;
export type CreateTourByGptMutationOptions = Apollo.BaseMutationOptions<
  CreateTourByGptMutation,
  CreateTourByGptMutationVariables
>;
export const GenerateTourAudioDocument = gql`
  mutation GenerateTourAudio($input: GenerateTourAudioInput!) {
    tour {
      generateTourAudio(input: $input) {
        id
        status
        datoTourId
      }
    }
  }
`;
export type GenerateTourAudioMutationFn = Apollo.MutationFunction<
  GenerateTourAudioMutation,
  GenerateTourAudioMutationVariables
>;

/**
 * __useGenerateTourAudioMutation__
 *
 * To run a mutation, you first call `useGenerateTourAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTourAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTourAudioMutation, { data, loading, error }] = useGenerateTourAudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateTourAudioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateTourAudioMutation,
    GenerateTourAudioMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateTourAudioMutation,
    GenerateTourAudioMutationVariables
  >(GenerateTourAudioDocument, options);
}
export type GenerateTourAudioMutationHookResult = ReturnType<
  typeof useGenerateTourAudioMutation
>;
export type GenerateTourAudioMutationResult =
  Apollo.MutationResult<GenerateTourAudioMutation>;
export type GenerateTourAudioMutationOptions = Apollo.BaseMutationOptions<
  GenerateTourAudioMutation,
  GenerateTourAudioMutationVariables
>;
export const TourAudioGenerationRequestUpdatedDocument = gql`
  subscription TourAudioGenerationRequestUpdated {
    tourAudioGenerationRequestUpdated {
      id
      status
      datoTourId
    }
  }
`;

/**
 * __useTourAudioGenerationRequestUpdatedSubscription__
 *
 * To run a query within a React component, call `useTourAudioGenerationRequestUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTourAudioGenerationRequestUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTourAudioGenerationRequestUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTourAudioGenerationRequestUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TourAudioGenerationRequestUpdatedSubscription,
    TourAudioGenerationRequestUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TourAudioGenerationRequestUpdatedSubscription,
    TourAudioGenerationRequestUpdatedSubscriptionVariables
  >(TourAudioGenerationRequestUpdatedDocument, options);
}
export type TourAudioGenerationRequestUpdatedSubscriptionHookResult =
  ReturnType<typeof useTourAudioGenerationRequestUpdatedSubscription>;
export type TourAudioGenerationRequestUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<TourAudioGenerationRequestUpdatedSubscription>;
export const GetTourCreationRequestStatisticsByUserDocument = gql`
  query GetTourCreationRequestStatisticsByUser {
    tourCreationRequest {
      getTourCreationRequestStatisticsByUser {
        userId
        total
        success
        inProgress
      }
    }
  }
`;

/**
 * __useGetTourCreationRequestStatisticsByUserQuery__
 *
 * To run a query within a React component, call `useGetTourCreationRequestStatisticsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTourCreationRequestStatisticsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTourCreationRequestStatisticsByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTourCreationRequestStatisticsByUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTourCreationRequestStatisticsByUserQuery,
    GetTourCreationRequestStatisticsByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTourCreationRequestStatisticsByUserQuery,
    GetTourCreationRequestStatisticsByUserQueryVariables
  >(GetTourCreationRequestStatisticsByUserDocument, options);
}
export function useGetTourCreationRequestStatisticsByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTourCreationRequestStatisticsByUserQuery,
    GetTourCreationRequestStatisticsByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTourCreationRequestStatisticsByUserQuery,
    GetTourCreationRequestStatisticsByUserQueryVariables
  >(GetTourCreationRequestStatisticsByUserDocument, options);
}
export type GetTourCreationRequestStatisticsByUserQueryHookResult = ReturnType<
  typeof useGetTourCreationRequestStatisticsByUserQuery
>;
export type GetTourCreationRequestStatisticsByUserLazyQueryHookResult =
  ReturnType<typeof useGetTourCreationRequestStatisticsByUserLazyQuery>;
export type GetTourCreationRequestStatisticsByUserQueryResult =
  Apollo.QueryResult<
    GetTourCreationRequestStatisticsByUserQuery,
    GetTourCreationRequestStatisticsByUserQueryVariables
  >;
export const TourCreationRequestUpdatedDocument = gql`
  subscription TourCreationRequestUpdated {
    tourCreationRequestUpdated {
      id
      status
      userTour {
        ...UserTourFields
      }
    }
  }
  ${UserTourFieldsFragmentDoc}
  ${UserTourStopFieldsFragmentDoc}
  ${UserStoryFieldsFragmentDoc}
  ${UserStorySlideFieldsFragmentDoc}
  ${UserQuizFieldsFragmentDoc}
  ${UserQuizTaskFieldsFragmentDoc}
`;

/**
 * __useTourCreationRequestUpdatedSubscription__
 *
 * To run a query within a React component, call `useTourCreationRequestUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTourCreationRequestUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTourCreationRequestUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTourCreationRequestUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TourCreationRequestUpdatedSubscription,
    TourCreationRequestUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TourCreationRequestUpdatedSubscription,
    TourCreationRequestUpdatedSubscriptionVariables
  >(TourCreationRequestUpdatedDocument, options);
}
export type TourCreationRequestUpdatedSubscriptionHookResult = ReturnType<
  typeof useTourCreationRequestUpdatedSubscription
>;
export type TourCreationRequestUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<TourCreationRequestUpdatedSubscription>;
export const GetTourRatingStatisticsDocument = gql`
  query GetTourRatingStatistics($input: GetTourRatingStatisticsInput!) {
    tourReview {
      getTourRatingStatistics(input: $input) {
        ...TourRatingStatisticsFields
      }
    }
  }
  ${TourRatingStatisticsFieldsFragmentDoc}
`;

/**
 * __useGetTourRatingStatisticsQuery__
 *
 * To run a query within a React component, call `useGetTourRatingStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTourRatingStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTourRatingStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTourRatingStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTourRatingStatisticsQuery,
    GetTourRatingStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTourRatingStatisticsQuery,
    GetTourRatingStatisticsQueryVariables
  >(GetTourRatingStatisticsDocument, options);
}
export function useGetTourRatingStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTourRatingStatisticsQuery,
    GetTourRatingStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTourRatingStatisticsQuery,
    GetTourRatingStatisticsQueryVariables
  >(GetTourRatingStatisticsDocument, options);
}
export type GetTourRatingStatisticsQueryHookResult = ReturnType<
  typeof useGetTourRatingStatisticsQuery
>;
export type GetTourRatingStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetTourRatingStatisticsLazyQuery
>;
export type GetTourRatingStatisticsQueryResult = Apollo.QueryResult<
  GetTourRatingStatisticsQuery,
  GetTourRatingStatisticsQueryVariables
>;
export const GetToursRatingStatisticsDocument = gql`
  query GetToursRatingStatistics($input: GetToursRatingStatisticsInput) {
    tourReview {
      getToursRatingStatistics(input: $input) {
        ...TourRatingStatisticsFields
      }
    }
  }
  ${TourRatingStatisticsFieldsFragmentDoc}
`;

/**
 * __useGetToursRatingStatisticsQuery__
 *
 * To run a query within a React component, call `useGetToursRatingStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToursRatingStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToursRatingStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetToursRatingStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetToursRatingStatisticsQuery,
    GetToursRatingStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetToursRatingStatisticsQuery,
    GetToursRatingStatisticsQueryVariables
  >(GetToursRatingStatisticsDocument, options);
}
export function useGetToursRatingStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetToursRatingStatisticsQuery,
    GetToursRatingStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetToursRatingStatisticsQuery,
    GetToursRatingStatisticsQueryVariables
  >(GetToursRatingStatisticsDocument, options);
}
export type GetToursRatingStatisticsQueryHookResult = ReturnType<
  typeof useGetToursRatingStatisticsQuery
>;
export type GetToursRatingStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetToursRatingStatisticsLazyQuery
>;
export type GetToursRatingStatisticsQueryResult = Apollo.QueryResult<
  GetToursRatingStatisticsQuery,
  GetToursRatingStatisticsQueryVariables
>;
export const GetActiveTransactionsDocument = gql`
  query GetActiveTransactions {
    transaction {
      getActiveTransactions {
        ...TransactionFields
      }
    }
  }
  ${TransactionFieldsFragmentDoc}
`;

/**
 * __useGetActiveTransactionsQuery__
 *
 * To run a query within a React component, call `useGetActiveTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveTransactionsQuery,
    GetActiveTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActiveTransactionsQuery,
    GetActiveTransactionsQueryVariables
  >(GetActiveTransactionsDocument, options);
}
export function useGetActiveTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveTransactionsQuery,
    GetActiveTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActiveTransactionsQuery,
    GetActiveTransactionsQueryVariables
  >(GetActiveTransactionsDocument, options);
}
export type GetActiveTransactionsQueryHookResult = ReturnType<
  typeof useGetActiveTransactionsQuery
>;
export type GetActiveTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetActiveTransactionsLazyQuery
>;
export type GetActiveTransactionsQueryResult = Apollo.QueryResult<
  GetActiveTransactionsQuery,
  GetActiveTransactionsQueryVariables
>;
export const TransactionCreatedDocument = gql`
  subscription TransactionCreated {
    transactionCreated {
      ...TransactionFields
    }
  }
  ${TransactionFieldsFragmentDoc}
`;

/**
 * __useTransactionCreatedSubscription__
 *
 * To run a query within a React component, call `useTransactionCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTransactionCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTransactionCreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TransactionCreatedSubscription,
    TransactionCreatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TransactionCreatedSubscription,
    TransactionCreatedSubscriptionVariables
  >(TransactionCreatedDocument, options);
}
export type TransactionCreatedSubscriptionHookResult = ReturnType<
  typeof useTransactionCreatedSubscription
>;
export type TransactionCreatedSubscriptionResult =
  Apollo.SubscriptionResult<TransactionCreatedSubscription>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    user {
      changePassword(input: $input)
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const DeleteMeDocument = gql`
  mutation DeleteMe {
    user {
      deleteMe
    }
  }
`;
export type DeleteMeMutationFn = Apollo.MutationFunction<
  DeleteMeMutation,
  DeleteMeMutationVariables
>;

/**
 * __useDeleteMeMutation__
 *
 * To run a mutation, you first call `useDeleteMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeMutation, { data, loading, error }] = useDeleteMeMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMeMutation,
    DeleteMeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMeMutation, DeleteMeMutationVariables>(
    DeleteMeDocument,
    options
  );
}
export type DeleteMeMutationHookResult = ReturnType<typeof useDeleteMeMutation>;
export type DeleteMeMutationResult = Apollo.MutationResult<DeleteMeMutation>;
export type DeleteMeMutationOptions = Apollo.BaseMutationOptions<
  DeleteMeMutation,
  DeleteMeMutationVariables
>;
export const DeleteMeConfirmDocument = gql`
  mutation DeleteMeConfirm($input: DeleteUserConfirmInput!) {
    user {
      deleteMeConfirm(input: $input)
    }
  }
`;
export type DeleteMeConfirmMutationFn = Apollo.MutationFunction<
  DeleteMeConfirmMutation,
  DeleteMeConfirmMutationVariables
>;

/**
 * __useDeleteMeConfirmMutation__
 *
 * To run a mutation, you first call `useDeleteMeConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeConfirmMutation, { data, loading, error }] = useDeleteMeConfirmMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMeConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMeConfirmMutation,
    DeleteMeConfirmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMeConfirmMutation,
    DeleteMeConfirmMutationVariables
  >(DeleteMeConfirmDocument, options);
}
export type DeleteMeConfirmMutationHookResult = ReturnType<
  typeof useDeleteMeConfirmMutation
>;
export type DeleteMeConfirmMutationResult =
  Apollo.MutationResult<DeleteMeConfirmMutation>;
export type DeleteMeConfirmMutationOptions = Apollo.BaseMutationOptions<
  DeleteMeConfirmMutation,
  DeleteMeConfirmMutationVariables
>;
export const MeDocument = gql`
  query Me {
    user {
      me {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${GroupSharingFieldsFragmentDoc}
  ${GroupSharingUserFieldsFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ResendDeleteMeVerificationEmailDocument = gql`
  mutation ResendDeleteMeVerificationEmail {
    user {
      resendDeleteMeVerificationEmail
    }
  }
`;
export type ResendDeleteMeVerificationEmailMutationFn = Apollo.MutationFunction<
  ResendDeleteMeVerificationEmailMutation,
  ResendDeleteMeVerificationEmailMutationVariables
>;

/**
 * __useResendDeleteMeVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendDeleteMeVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendDeleteMeVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendDeleteMeVerificationEmailMutation, { data, loading, error }] = useResendDeleteMeVerificationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendDeleteMeVerificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendDeleteMeVerificationEmailMutation,
    ResendDeleteMeVerificationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendDeleteMeVerificationEmailMutation,
    ResendDeleteMeVerificationEmailMutationVariables
  >(ResendDeleteMeVerificationEmailDocument, options);
}
export type ResendDeleteMeVerificationEmailMutationHookResult = ReturnType<
  typeof useResendDeleteMeVerificationEmailMutation
>;
export type ResendDeleteMeVerificationEmailMutationResult =
  Apollo.MutationResult<ResendDeleteMeVerificationEmailMutation>;
export type ResendDeleteMeVerificationEmailMutationOptions =
  Apollo.BaseMutationOptions<
    ResendDeleteMeVerificationEmailMutation,
    ResendDeleteMeVerificationEmailMutationVariables
  >;
export const SetOneSignalSubscriptionIdDocument = gql`
  mutation SetOneSignalSubscriptionId(
    $input: SetOneSignalSubscriptionIdInput!
  ) {
    user {
      setOneSignalSubscriptionId(input: $input)
    }
  }
`;
export type SetOneSignalSubscriptionIdMutationFn = Apollo.MutationFunction<
  SetOneSignalSubscriptionIdMutation,
  SetOneSignalSubscriptionIdMutationVariables
>;

/**
 * __useSetOneSignalSubscriptionId__
 *
 * To run a mutation, you first call `useSetOneSignalSubscriptionId` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOneSignalSubscriptionId` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOneSignalSubscriptionId, { data, loading, error }] = useSetOneSignalSubscriptionId({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOneSignalSubscriptionId(
  baseOptions?: Apollo.MutationHookOptions<
    SetOneSignalSubscriptionIdMutation,
    SetOneSignalSubscriptionIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOneSignalSubscriptionIdMutation,
    SetOneSignalSubscriptionIdMutationVariables
  >(SetOneSignalSubscriptionIdDocument, options);
}
export type SetOneSignalSubscriptionIdHookResult = ReturnType<
  typeof useSetOneSignalSubscriptionId
>;
export type SetOneSignalSubscriptionIdMutationResult =
  Apollo.MutationResult<SetOneSignalSubscriptionIdMutation>;
export type SetOneSignalSubscriptionIdMutationOptions =
  Apollo.BaseMutationOptions<
    SetOneSignalSubscriptionIdMutation,
    SetOneSignalSubscriptionIdMutationVariables
  >;
export const UpdateMeDocument = gql`
  mutation UpdateMe($input: UpdateUserInput!) {
    user {
      updateMe(input: $input) {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${GroupSharingFieldsFragmentDoc}
  ${GroupSharingUserFieldsFragmentDoc}
`;
export type UpdateMeMutationFn = Apollo.MutationFunction<
  UpdateMeMutation,
  UpdateMeMutationVariables
>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeMutation,
    UpdateMeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(
    UpdateMeDocument,
    options
  );
}
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeMutation,
  UpdateMeMutationVariables
>;
export const GetUserQuizTaskByWithParentsDocument = gql`
  query GetUserQuizTaskByWithParents($input: GetUserQuizTaskByInput!) {
    userQuizTask {
      getUserQuizTaskBy(input: $input) {
        ...UserQuizTaskFields
        ...UserQuizTaskParentFields
      }
    }
  }
  ${UserQuizTaskFieldsFragmentDoc}
  ${UserQuizTaskParentFieldsFragmentDoc}
`;

/**
 * __useGetUserQuizTaskByWithParentsQuery__
 *
 * To run a query within a React component, call `useGetUserQuizTaskByWithParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuizTaskByWithParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuizTaskByWithParentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserQuizTaskByWithParentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserQuizTaskByWithParentsQuery,
    GetUserQuizTaskByWithParentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserQuizTaskByWithParentsQuery,
    GetUserQuizTaskByWithParentsQueryVariables
  >(GetUserQuizTaskByWithParentsDocument, options);
}
export function useGetUserQuizTaskByWithParentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserQuizTaskByWithParentsQuery,
    GetUserQuizTaskByWithParentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserQuizTaskByWithParentsQuery,
    GetUserQuizTaskByWithParentsQueryVariables
  >(GetUserQuizTaskByWithParentsDocument, options);
}
export type GetUserQuizTaskByWithParentsQueryHookResult = ReturnType<
  typeof useGetUserQuizTaskByWithParentsQuery
>;
export type GetUserQuizTaskByWithParentsLazyQueryHookResult = ReturnType<
  typeof useGetUserQuizTaskByWithParentsLazyQuery
>;
export type GetUserQuizTaskByWithParentsQueryResult = Apollo.QueryResult<
  GetUserQuizTaskByWithParentsQuery,
  GetUserQuizTaskByWithParentsQueryVariables
>;
export const UpdateUserQuizTaskDocument = gql`
  mutation UpdateUserQuizTask($input: UpdateUserQuizTaskInput!) {
    userQuizTask {
      updateUserQuizTask(input: $input) {
        ...UserQuizTaskFields
      }
    }
  }
  ${UserQuizTaskFieldsFragmentDoc}
`;
export type UpdateUserQuizTaskMutationFn = Apollo.MutationFunction<
  UpdateUserQuizTaskMutation,
  UpdateUserQuizTaskMutationVariables
>;

/**
 * __useUpdateUserQuizTaskMutation__
 *
 * To run a mutation, you first call `useUpdateUserQuizTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserQuizTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserQuizTaskMutation, { data, loading, error }] = useUpdateUserQuizTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserQuizTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserQuizTaskMutation,
    UpdateUserQuizTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserQuizTaskMutation,
    UpdateUserQuizTaskMutationVariables
  >(UpdateUserQuizTaskDocument, options);
}
export type UpdateUserQuizTaskMutationHookResult = ReturnType<
  typeof useUpdateUserQuizTaskMutation
>;
export type UpdateUserQuizTaskMutationResult =
  Apollo.MutationResult<UpdateUserQuizTaskMutation>;
export type UpdateUserQuizTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserQuizTaskMutation,
  UpdateUserQuizTaskMutationVariables
>;
export const UpdateUserQuizTaskAndGetParentsDocument = gql`
  mutation UpdateUserQuizTaskAndGetParents($input: UpdateUserQuizTaskInput!) {
    userQuizTask {
      updateUserQuizTask(input: $input) {
        ...UserQuizTaskFields
        ...UserQuizTaskParentFields
      }
    }
  }
  ${UserQuizTaskFieldsFragmentDoc}
  ${UserQuizTaskParentFieldsFragmentDoc}
`;
export type UpdateUserQuizTaskAndGetParentsMutationFn = Apollo.MutationFunction<
  UpdateUserQuizTaskAndGetParentsMutation,
  UpdateUserQuizTaskAndGetParentsMutationVariables
>;

/**
 * __useUpdateUserQuizTaskAndGetParentsMutation__
 *
 * To run a mutation, you first call `useUpdateUserQuizTaskAndGetParentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserQuizTaskAndGetParentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserQuizTaskAndGetParentsMutation, { data, loading, error }] = useUpdateUserQuizTaskAndGetParentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserQuizTaskAndGetParentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserQuizTaskAndGetParentsMutation,
    UpdateUserQuizTaskAndGetParentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserQuizTaskAndGetParentsMutation,
    UpdateUserQuizTaskAndGetParentsMutationVariables
  >(UpdateUserQuizTaskAndGetParentsDocument, options);
}
export type UpdateUserQuizTaskAndGetParentsMutationHookResult = ReturnType<
  typeof useUpdateUserQuizTaskAndGetParentsMutation
>;
export type UpdateUserQuizTaskAndGetParentsMutationResult =
  Apollo.MutationResult<UpdateUserQuizTaskAndGetParentsMutation>;
export type UpdateUserQuizTaskAndGetParentsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserQuizTaskAndGetParentsMutation,
    UpdateUserQuizTaskAndGetParentsMutationVariables
  >;
export const CreateUserStoryDocument = gql`
  mutation CreateUserStory($input: CreateUserStoryInput!) {
    userStory {
      createUserStory(input: $input) {
        ...UserStoryFields
      }
    }
  }
  ${UserStoryFieldsFragmentDoc}
  ${UserStorySlideFieldsFragmentDoc}
  ${UserQuizFieldsFragmentDoc}
  ${UserQuizTaskFieldsFragmentDoc}
`;
export type CreateUserStoryMutationFn = Apollo.MutationFunction<
  CreateUserStoryMutation,
  CreateUserStoryMutationVariables
>;

/**
 * __useCreateUserStoryMutation__
 *
 * To run a mutation, you first call `useCreateUserStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserStoryMutation, { data, loading, error }] = useCreateUserStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserStoryMutation,
    CreateUserStoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserStoryMutation,
    CreateUserStoryMutationVariables
  >(CreateUserStoryDocument, options);
}
export type CreateUserStoryMutationHookResult = ReturnType<
  typeof useCreateUserStoryMutation
>;
export type CreateUserStoryMutationResult =
  Apollo.MutationResult<CreateUserStoryMutation>;
export type CreateUserStoryMutationOptions = Apollo.BaseMutationOptions<
  CreateUserStoryMutation,
  CreateUserStoryMutationVariables
>;
export const GetUserStoriesCreatedByMeDocument = gql`
  query GetUserStoriesCreatedByMe {
    userStory {
      getUserStoriesCreatedByMe {
        id
        datoStoryId
      }
    }
  }
`;

/**
 * __useGetUserStoriesCreatedByMeQuery__
 *
 * To run a query within a React component, call `useGetUserStoriesCreatedByMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStoriesCreatedByMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStoriesCreatedByMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserStoriesCreatedByMeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserStoriesCreatedByMeQuery,
    GetUserStoriesCreatedByMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserStoriesCreatedByMeQuery,
    GetUserStoriesCreatedByMeQueryVariables
  >(GetUserStoriesCreatedByMeDocument, options);
}
export function useGetUserStoriesCreatedByMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserStoriesCreatedByMeQuery,
    GetUserStoriesCreatedByMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserStoriesCreatedByMeQuery,
    GetUserStoriesCreatedByMeQueryVariables
  >(GetUserStoriesCreatedByMeDocument, options);
}
export type GetUserStoriesCreatedByMeQueryHookResult = ReturnType<
  typeof useGetUserStoriesCreatedByMeQuery
>;
export type GetUserStoriesCreatedByMeLazyQueryHookResult = ReturnType<
  typeof useGetUserStoriesCreatedByMeLazyQuery
>;
export type GetUserStoriesCreatedByMeQueryResult = Apollo.QueryResult<
  GetUserStoriesCreatedByMeQuery,
  GetUserStoriesCreatedByMeQueryVariables
>;
export const GetViewedUserStoriesDocument = gql`
  query GetViewedUserStories {
    userStory {
      getViewedUserStories {
        id
        datoStoryId
      }
    }
  }
`;

/**
 * __useGetViewedUserStoriesQuery__
 *
 * To run a query within a React component, call `useGetViewedUserStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewedUserStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewedUserStoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetViewedUserStoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetViewedUserStoriesQuery,
    GetViewedUserStoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetViewedUserStoriesQuery,
    GetViewedUserStoriesQueryVariables
  >(GetViewedUserStoriesDocument, options);
}
export function useGetViewedUserStoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetViewedUserStoriesQuery,
    GetViewedUserStoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetViewedUserStoriesQuery,
    GetViewedUserStoriesQueryVariables
  >(GetViewedUserStoriesDocument, options);
}
export type GetViewedUserStoriesQueryHookResult = ReturnType<
  typeof useGetViewedUserStoriesQuery
>;
export type GetViewedUserStoriesLazyQueryHookResult = ReturnType<
  typeof useGetViewedUserStoriesLazyQuery
>;
export type GetViewedUserStoriesQueryResult = Apollo.QueryResult<
  GetViewedUserStoriesQuery,
  GetViewedUserStoriesQueryVariables
>;
export const UpdateUserStorySlideDocument = gql`
  mutation UpdateUserStorySlide($input: UpdateUserStorySlideInput!) {
    userStorySlide {
      updateUserStorySlide(input: $input) {
        id
        userStory {
          id
          datoStoryId
        }
      }
    }
  }
`;
export type UpdateUserStorySlideMutationFn = Apollo.MutationFunction<
  UpdateUserStorySlideMutation,
  UpdateUserStorySlideMutationVariables
>;

/**
 * __useUpdateUserStorySlideMutation__
 *
 * To run a mutation, you first call `useUpdateUserStorySlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStorySlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStorySlideMutation, { data, loading, error }] = useUpdateUserStorySlideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserStorySlideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserStorySlideMutation,
    UpdateUserStorySlideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserStorySlideMutation,
    UpdateUserStorySlideMutationVariables
  >(UpdateUserStorySlideDocument, options);
}
export type UpdateUserStorySlideMutationHookResult = ReturnType<
  typeof useUpdateUserStorySlideMutation
>;
export type UpdateUserStorySlideMutationResult =
  Apollo.MutationResult<UpdateUserStorySlideMutation>;
export type UpdateUserStorySlideMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserStorySlideMutation,
  UpdateUserStorySlideMutationVariables
>;
export const CreateUserStorySlideAudioProgressDocument = gql`
  mutation CreateUserStorySlideAudioProgress(
    $input: CreateUserStorySlideAudioProgressInput!
  ) {
    userStorySlideAudioProgress {
      createUserStorySlideAudioProgress(input: $input) {
        id
      }
    }
  }
`;
export type CreateUserStorySlideAudioProgressMutationFn =
  Apollo.MutationFunction<
    CreateUserStorySlideAudioProgressMutation,
    CreateUserStorySlideAudioProgressMutationVariables
  >;

/**
 * __useCreateUserStorySlideAudioProgressMutation__
 *
 * To run a mutation, you first call `useCreateUserStorySlideAudioProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserStorySlideAudioProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserStorySlideAudioProgressMutation, { data, loading, error }] = useCreateUserStorySlideAudioProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserStorySlideAudioProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserStorySlideAudioProgressMutation,
    CreateUserStorySlideAudioProgressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserStorySlideAudioProgressMutation,
    CreateUserStorySlideAudioProgressMutationVariables
  >(CreateUserStorySlideAudioProgressDocument, options);
}
export type CreateUserStorySlideAudioProgressMutationHookResult = ReturnType<
  typeof useCreateUserStorySlideAudioProgressMutation
>;
export type CreateUserStorySlideAudioProgressMutationResult =
  Apollo.MutationResult<CreateUserStorySlideAudioProgressMutation>;
export type CreateUserStorySlideAudioProgressMutationOptions =
  Apollo.BaseMutationOptions<
    CreateUserStorySlideAudioProgressMutation,
    CreateUserStorySlideAudioProgressMutationVariables
  >;
export const CreateUserTourDocument = gql`
  mutation CreateUserTour($input: CreateUserTourInput!) {
    userTour {
      createUserTour(input: $input) {
        ...UserTourFields
      }
    }
  }
  ${UserTourFieldsFragmentDoc}
  ${UserTourStopFieldsFragmentDoc}
  ${UserStoryFieldsFragmentDoc}
  ${UserStorySlideFieldsFragmentDoc}
  ${UserQuizFieldsFragmentDoc}
  ${UserQuizTaskFieldsFragmentDoc}
`;
export type CreateUserTourMutationFn = Apollo.MutationFunction<
  CreateUserTourMutation,
  CreateUserTourMutationVariables
>;

/**
 * __useCreateUserTourMutation__
 *
 * To run a mutation, you first call `useCreateUserTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserTourMutation, { data, loading, error }] = useCreateUserTourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserTourMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserTourMutation,
    CreateUserTourMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserTourMutation,
    CreateUserTourMutationVariables
  >(CreateUserTourDocument, options);
}
export type CreateUserTourMutationHookResult = ReturnType<
  typeof useCreateUserTourMutation
>;
export type CreateUserTourMutationResult =
  Apollo.MutationResult<CreateUserTourMutation>;
export type CreateUserTourMutationOptions = Apollo.BaseMutationOptions<
  CreateUserTourMutation,
  CreateUserTourMutationVariables
>;
export const GetUserTourDocument = gql`
  query GetUserTour($input: GetUserTourInput!) {
    userTour {
      getUserTour(input: $input) {
        ...UserTourFields
      }
    }
  }
  ${UserTourFieldsFragmentDoc}
  ${UserTourStopFieldsFragmentDoc}
  ${UserStoryFieldsFragmentDoc}
  ${UserStorySlideFieldsFragmentDoc}
  ${UserQuizFieldsFragmentDoc}
  ${UserQuizTaskFieldsFragmentDoc}
`;

/**
 * __useGetUserTourQuery__
 *
 * To run a query within a React component, call `useGetUserTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTourQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserTourQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserTourQuery,
    GetUserTourQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserTourQuery, GetUserTourQueryVariables>(
    GetUserTourDocument,
    options
  );
}
export function useGetUserTourLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserTourQuery,
    GetUserTourQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserTourQuery, GetUserTourQueryVariables>(
    GetUserTourDocument,
    options
  );
}
export type GetUserTourQueryHookResult = ReturnType<typeof useGetUserTourQuery>;
export type GetUserTourLazyQueryHookResult = ReturnType<
  typeof useGetUserTourLazyQuery
>;
export type GetUserTourQueryResult = Apollo.QueryResult<
  GetUserTourQuery,
  GetUserTourQueryVariables
>;
export const GetUserTourAudioStatisticsDocument = gql`
  query GetUserTourAudioStatistics($input: GetUserTourAudioStatisticsInput!) {
    userTour {
      getUserTourAudioStatistics(input: $input) {
        datoTourId
        audioProgress
        userTourStopsAudioStatistics {
          datoTourStopId
          audioProgress
        }
      }
    }
  }
`;

/**
 * __useGetUserTourAudioStatisticsQuery__
 *
 * To run a query within a React component, call `useGetUserTourAudioStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTourAudioStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTourAudioStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserTourAudioStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserTourAudioStatisticsQuery,
    GetUserTourAudioStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserTourAudioStatisticsQuery,
    GetUserTourAudioStatisticsQueryVariables
  >(GetUserTourAudioStatisticsDocument, options);
}
export function useGetUserTourAudioStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserTourAudioStatisticsQuery,
    GetUserTourAudioStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserTourAudioStatisticsQuery,
    GetUserTourAudioStatisticsQueryVariables
  >(GetUserTourAudioStatisticsDocument, options);
}
export type GetUserTourAudioStatisticsQueryHookResult = ReturnType<
  typeof useGetUserTourAudioStatisticsQuery
>;
export type GetUserTourAudioStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetUserTourAudioStatisticsLazyQuery
>;
export type GetUserTourAudioStatisticsQueryResult = Apollo.QueryResult<
  GetUserTourAudioStatisticsQuery,
  GetUserTourAudioStatisticsQueryVariables
>;
export const GetUserToursAudioStatisticsDocument = gql`
  query GetUserToursAudioStatistics($input: GetUserToursAudioStatisticsInput) {
    userTour {
      getUserToursAudioStatistics(input: $input) {
        datoTourId
        audioProgress
      }
    }
  }
`;

/**
 * __useGetUserToursAudioStatisticsQuery__
 *
 * To run a query within a React component, call `useGetUserToursAudioStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserToursAudioStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserToursAudioStatisticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserToursAudioStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserToursAudioStatisticsQuery,
    GetUserToursAudioStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserToursAudioStatisticsQuery,
    GetUserToursAudioStatisticsQueryVariables
  >(GetUserToursAudioStatisticsDocument, options);
}
export function useGetUserToursAudioStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserToursAudioStatisticsQuery,
    GetUserToursAudioStatisticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserToursAudioStatisticsQuery,
    GetUserToursAudioStatisticsQueryVariables
  >(GetUserToursAudioStatisticsDocument, options);
}
export type GetUserToursAudioStatisticsQueryHookResult = ReturnType<
  typeof useGetUserToursAudioStatisticsQuery
>;
export type GetUserToursAudioStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetUserToursAudioStatisticsLazyQuery
>;
export type GetUserToursAudioStatisticsQueryResult = Apollo.QueryResult<
  GetUserToursAudioStatisticsQuery,
  GetUserToursAudioStatisticsQueryVariables
>;
export const GetUserToursCreatedByMeDocument = gql`
  query GetUserToursCreatedByMe {
    userTour {
      getUserToursCreatedByMe {
        id
        datoTourId
      }
    }
  }
`;

/**
 * __useGetUserToursCreatedByMeQuery__
 *
 * To run a query within a React component, call `useGetUserToursCreatedByMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserToursCreatedByMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserToursCreatedByMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserToursCreatedByMeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserToursCreatedByMeQuery,
    GetUserToursCreatedByMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserToursCreatedByMeQuery,
    GetUserToursCreatedByMeQueryVariables
  >(GetUserToursCreatedByMeDocument, options);
}
export function useGetUserToursCreatedByMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserToursCreatedByMeQuery,
    GetUserToursCreatedByMeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserToursCreatedByMeQuery,
    GetUserToursCreatedByMeQueryVariables
  >(GetUserToursCreatedByMeDocument, options);
}
export type GetUserToursCreatedByMeQueryHookResult = ReturnType<
  typeof useGetUserToursCreatedByMeQuery
>;
export type GetUserToursCreatedByMeLazyQueryHookResult = ReturnType<
  typeof useGetUserToursCreatedByMeLazyQuery
>;
export type GetUserToursCreatedByMeQueryResult = Apollo.QueryResult<
  GetUserToursCreatedByMeQuery,
  GetUserToursCreatedByMeQueryVariables
>;
export const ResetUserTourDocument = gql`
  mutation ResetUserTour($input: ResetUserTourInput!) {
    userTour {
      resetUserTour(input: $input) {
        id
      }
    }
  }
`;
export type ResetUserTourMutationFn = Apollo.MutationFunction<
  ResetUserTourMutation,
  ResetUserTourMutationVariables
>;

/**
 * __useResetUserTourMutation__
 *
 * To run a mutation, you first call `useResetUserTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserTourMutation, { data, loading, error }] = useResetUserTourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserTourMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserTourMutation,
    ResetUserTourMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetUserTourMutation,
    ResetUserTourMutationVariables
  >(ResetUserTourDocument, options);
}
export type ResetUserTourMutationHookResult = ReturnType<
  typeof useResetUserTourMutation
>;
export type ResetUserTourMutationResult =
  Apollo.MutationResult<ResetUserTourMutation>;
export type ResetUserTourMutationOptions = Apollo.BaseMutationOptions<
  ResetUserTourMutation,
  ResetUserTourMutationVariables
>;
