import { every, find, flatMap, intersection, map, reduce, round } from 'lodash-es';

import { Category, StorySlide, Tour, TourStop } from '../interfaces/Interfaces';

const getPreviousTourStop = (tourStop: TourStop): TourStop | null | undefined => {
  const tourStops = tourStop?.tour?.tourStops;
  if (!tourStops?.length) return null;

  const tourStopIndex = tourStops.indexOf(tourStop);
  const previousTourStop = tourStops[tourStopIndex - 1];
  // avoid navigation to preview tour stop
  return previousTourStop?.isPreviewTourStop ? null : previousTourStop;
};

const getNextTourStop = (tourStop: TourStop): TourStop | null | undefined => {
  const tourStops = tourStop?.tour?.tourStops;
  if (!tourStops?.length) return null;

  const tourStopIndex = tourStops.indexOf(tourStop);
  return tourStops[tourStopIndex + 1];
};

const checkIfTourSelectedByCategory = (
  tour: Tour,
  selectedCategoriesInLocale: string[],
  categoriesInLocale?: Category[],
) => {
  // if all categories are selected in the filter mark the story as selected even if any category isn't attached to it
  if (selectedCategoriesInLocale?.length === categoriesInLocale?.length) {
    return true;
  }

  const storyCategoryIds = map(tour?.categories, category => category.id);
  return !!intersection(storyCategoryIds, selectedCategoriesInLocale)?.length;
};

const isFreeTour = (tour: Tour) => {
  return tour.currentTourPrice === 0 || !tour.regularTourPrice;
};

const isAccessibleTour = (tour: Tour, accessibleTourIds: string[]) => {
  return isFreeTour(tour) || accessibleTourIds?.includes(tour.id);
};

const getTourAudioDurationInMinutes = (tour: Tour) => {
  if (!tour) return 0;
  if (tour.audioDurationInMinutes) return tour.audioDurationInMinutes;

  const tourStops = tour.tourStops || [];
  const tourDurationInSeconds = reduce(tourStops, (tourAcc, tourStop) => {
    const stories = tourStop.stories || [];
    const tourStopDuration = reduce(stories, (tourStopAcc, story) => {
      const storySlides = story.storySlides || [];
      const storyDuration = story.audioDurationInSeconds || reduce(storySlides, (storyAcc, storySlide) => {
        return storyAcc + (storySlide.audioDurationInSeconds || 0);
      }, 0);
      return tourStopAcc + storyDuration;
    }, 0);
    return tourAcc + tourStopDuration;
  }, 0);
  return round(tourDurationInSeconds / 60);
}

const checkIfAllAudioFilesExist = (tour: Tour, hasPremiumAccess: boolean, selectedVoiceId?: string | null) => {
  const storySlides: StorySlide[] = flatMap(tour.tourStops, (tourStop) =>
    flatMap(tourStop.stories, 'storySlides')
  );

  const voiceId = hasPremiumAccess && selectedVoiceId;

  return every(storySlides, (storySlide) => {
    if (voiceId) {
      const currentVoiceAudio = find(storySlide?.slideVoiceAudios, ['voice.id', selectedVoiceId]);
      return !!currentVoiceAudio?.audio;
    }

    return !!storySlide.slideAudio;
  });
}

export {
  getPreviousTourStop,
  getNextTourStop,
  checkIfTourSelectedByCategory,
  checkIfAllAudioFilesExist,
  isFreeTour,
  isAccessibleTour,
  getTourAudioDurationInMinutes
};
