import { useEffect } from 'react';
import { IonIcon, IonProgressBar, IonSpinner } from '@ionic/react';
import {
  pauseCircleSharp as pauseIcon,
  playCircleSharp as playIcon,
} from "ionicons/icons";
import { useMediaPlayer } from "../../contexts/MediaPlayerContext";
import useStoryPlayer from "../../hooks/useStoryPlayer";
import DetachFromGroupSharingButton from '../buttons/DetachFromGroupSharingButton';
import { useTransaction } from '../../contexts/TransactionContext';
import { isAccessibleTour } from '../../helpers/tour-helpers';
import useGroupSharingStore from '../../stores/useGroupSharingStore';
import { isPremiumStory } from '../../helpers/story-helpers';

const MiniPlayer: React.FC = () => {
  const {
    currentTour,
    currentTourStop,
    currentStory,
    isPlaying,
    percentagePlayed,
    togglePlayPause,
    isLoading,
  } = useMediaPlayer();
  const { navigateToCurrentStory } = useStoryPlayer();
  const { hasPremiumAccess, accessibleTourIds } = useTransaction();
  const { unloadMediaPlayer } = useMediaPlayer();

  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);

  // close mini player if user doesn't have premium access or access to the tour, the tour or story is not free,
  // and the user is not in group sharing mode
  useEffect(() => {
    if (
      ((currentTour && !isAccessibleTour(currentTour, accessibleTourIds)) || (currentStory && isPremiumStory(currentStory))) &&
      !hasPremiumAccess &&
      !myGroupSharing
    ) {
      unloadMediaPlayer();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTour, currentStory, accessibleTourIds, hasPremiumAccess, myGroupSharing]
  );

  // Only render the player if there is a current tour stop
  if (!currentStory) {
    return null;
  }

  const storyTitle = currentStory.title;
  const tourStopTitle = currentTourStop?.title;
  const tourTitle = currentTour?.title;
  const creatorName = currentStory.creatorProfile?.creatorName;

  let storyMeta: string | null = null;

  if (tourStopTitle && tourStopTitle !== storyTitle) {
    storyMeta = tourStopTitle;
  }

  if (tourTitle) {
    storyMeta = storyMeta ? `${storyMeta} - ${tourTitle}` : tourTitle;
  }

  if (creatorName) {
    storyMeta = storyMeta ? `${storyMeta} - ${creatorName}` : creatorName;
  }

  return (
    <div className="bg-primary/90">
      <IonProgressBar value={percentagePlayed} />
      <div
        className="mx-auto flex max-w-xl cursor-pointer items-center justify-between px-3 pb-[6px] pt-[5px]"
        onClick={() => navigateToCurrentStory()}
      >
        <div className="grow truncate">
          {storyMeta && (
            <div className="truncate text-xs font-bold leading-4 text-white">
              {storyMeta}
            </div>
          )}

          <div className="mt-px truncate text-[1rem] font-semibold leading-[1.2rem] text-white">
            {currentStory.title}
          </div>
        </div>

        <DetachFromGroupSharingButton />

        {isLoading ? <div
          className="w-[40px] h-[40px] m-[6px] flex items-center justify-center bg-white rounded-full shrink-0"
        ><IonSpinner name="bubbles" className="text-primary" /></div> : <IonIcon
          icon={isPlaying ? pauseIcon : playIcon}
          className="m-0 ml-1.5 shrink-0 text-slate-50"
          style={{
            fontSize: "52px",
          }}
          onClick={(e) => {
            // Prevent triggering the overlay player
            e.stopPropagation();

            togglePlayPause();
          }}
        />}
      </div>
    </div>
  );
};

export default MiniPlayer;
