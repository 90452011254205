import React from 'react';
import {
  IonButton,
  IonContent, IonIcon,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  useIonRouter,
  useIonViewDidEnter
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { pauseCircle, playCircle } from 'ionicons/icons';
import { useAudio } from 'react-use';
import { find, get } from 'lodash-es';

import AppLayout from '../layouts/AppLayout';
import useIonVisible from '../hooks/useIonVisible';
import useAuthStore from '../stores/useAuthStore';
import { useTransaction } from '../contexts/TransactionContext';
import './PremiumAccessPurchasingPage.scss';
import useRoutes from '../hooks/useRoutes';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import useVoices from '../hooks/useVoices';
import { Voice } from '../interfaces/Interfaces';
import './VoicesPage.scss';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import { useUpdateMeMutation } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { getFirstBackendValidationError } from '../helpers/error-helpers';
import useToast from '../hooks/useToast';

const VoicesPage: React.FC = () => {
  const router = useIonRouter();
  const { isVisible } = useIonVisible();
  const { hasPremiumAccess, activeTransactionsLoading } = useTransaction();
  const { t } = useTranslation();
  const { presentToast } = useToast();
  const { currentPath, premiumAccessPurchasingPath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { voices, defaultVoice, isAllVoicesReceived } = useVoices(isVisible);
  const [audio, state, controls, audioRef] = useAudio({
    src: '',
  });
  const [updateMeMutation] = useUpdateMeMutation();

  const user = useAuthStore(state => state.me);
  const setMe = useAuthStore(state => state.setMe);
  const isUserInitialised = useAuthStore(state => state.isUserInitialised);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_VOICES);
    }
  });

  const playStopAudio = (voice: Voice) => {
    if (!audioRef?.current || !voice?.audio?.url) return;

    if (audioRef.current.src === voice.audio.url) {
      state.playing ? controls.pause() : controls.play();
    } else {
      audioRef.current.src = voice.audio.url;
      audioRef.current.load();
      controls.play();
    }
  };

  const changeVoice = async (voice: Voice) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.CHANGE_VOICE, {
        ...user?.profile?.datoVoiceId ? {
          currentVoiceName: user.profile.datoVoiceId,
          currentVoiceId: get(find(voices, ['id', user.profile.datoVoiceId]), 'name')
        } : {},
        newVoiceName: voice.name,
        newVoiceId: voice.id,
        hasPremiumAccess,
      });
    }

    if (!hasPremiumAccess) {
      router.push(premiumAccessPurchasingPath(currentPath()));
      return;
    }

    try {
      const { data } = await updateMeMutation({ variables: {
        input: { profile: { datoVoiceId: voice.id }},
      }});
      const me = data?.user?.updateMe;

      if (me) {
        setMe(me);
        presentToast('voices.toasts.updateVoice.success');
      }
    } catch (e: any) {
      const backendError = getFirstBackendValidationError(e);
      presentToast(backendError, 'danger');
    }
  };

  return (
    <AppLayout>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative mx-auto min-h-full max-w-xl bg-white">
          <FloatingBackButton style={{ left: '6px' }}/>

          <div className="px-5 pb-5 pt-[75px]">
            <h2 className="text-[1.125rem] text-[#232437] font-bold mb-2">{t('voices.title')}</h2>
            <div className="text-[1rem] text-[#535E69] font-bold mb-5">{t('voices.description')}</div>

            <IonList className="bg-white mb-5">
              <IonRadioGroup value={hasPremiumAccess ? user?.profile?.datoVoiceId || defaultVoice?.id : null}>
                {voices?.map((voice) => <IonItem
                  key={voice.id}
                  lines="none"
                  className="mb-2"
                  style={{
                    '--background': '#ffffff',
                  }}
                >
                  <IonRadio
                    value={voice.id}
                    slot="start"
                    mode="ios"
                    color="dark"
                    className="item-voices-radio"
                    disabled={!isUserInitialised || activeTransactionsLoading || !isAllVoicesReceived}
                    onClick={() => changeVoice(voice)}
                  />
                  <div className="flex w-full justify-between items-center">
                    <div>
                      <div className={clsx('text-[1rem] font-semibold mr-2', 'text-primary')}>{voice.name}</div>
                      <div className="text-[0.75rem] text-[#89949E] font-medium">{voice.description}</div>
                    </div>
                    <IonButton
                      shape="round"
                      className="h-9 w-9"
                      fill="clear"
                      onClick={(e) => {
                        e.stopPropagation();
                        playStopAudio(voice);
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={audioRef.current?.src === voice.audio?.url && state.playing ? pauseCircle : playCircle}
                        className="w-full h-full text-primary"
                      />
                    </IonButton>
                  </div>
                </IonItem>)}
              </IonRadioGroup>
            </IonList>

            <div className="text-[0.875rem] text-[#232437] font-bold">{t('voices.note.title')}</div>
            <div className="text-[0.8125rem] text-[#89949E] font-medium">{t('voices.note.text')}</div>
          </div>
        </div>
        <div id="current-audio-tag">{audio}</div>
      </IonContent>
    </AppLayout>
  );
};

export default VoicesPage;
