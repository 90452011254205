import { createContext, useContext, useEffect, useState } from "react";
import mixpanel, { Config as MixpanelConfig } from "mixpanel-browser";

import useAuthStore from '../stores/useAuthStore';

export enum MixpanelEvents {
  VIEW_ONBOARDING = "Onboarding View",
  ONBOARDING_SKIP = "Onboarding Skip",
  ONBOARDING_COMPLETE = "Onboarding Complete",

  VIEW_HOME = "Home View",
  HOME_TOURS_BUTTON = "Tours Button Clicking At Home",
  HOME_STORIES_BUTTON = "Stories Button Clicking At Home",

  VIEW_CITIES = "Cities View",

  VIEW_SIGN_UP_OR_LOGIN = "Sign Up Or Log In View",
  VIEW_LOG_IN = "Log In View",
  LOG_IN_VIA_GOOGLE = "Log In Via Google",
  LOG_IN_VIA_APPLE = "Log In Via Apple",
  LOG_IN_VIA_FACEBOOK = "Log In Via Facebook",
  LOG_IN_VIA_EMAIL_AND_PASSWORD = "Log In Via Email And Password",
  LOG_IN_SUCCESS = "Logged In Successfully",
  VIEW_SIGN_UP = "Sign Up View",
  SIGN_UP_VIA_GOOGLE = "Sign Up Via Google",
  SIGN_UP_VIA_APPLE = "Sign Up Via Apple",
  SIGN_UP_VIA_FACEBOOK = "Sign Up Via Facebook",
  SIGN_UP_VIA_EMAIL_AND_PASSWORD = "Sign Up Via Email And Password",
  VIEW_SIGN_UP_CONFIRMATION = "Sign Up Confirmation View",
  SIGN_UP_CONFIRM = "Sign Up Confirm",
  SIGN_UP_SUCCESS = "Signed Up Successfully",
  SIGN_UP_RESEND_VERIFICATION_CODE = "Sign Up Resend Verification Code",

  VIEW_RESET_PASSWORD = "Reset Password View",
  RESET_PASSWORD = "Reset Password",
  RESET_PASSWORD_RESEND_VERIFICATION_CODE = "Reset Password Resend Verification Code",
  VIEW_RESET_PASSWORD_CONFIRMATION = "Reset Password Confirmation View",
  RESET_PASSWORD_CONFIRM = "Reset Password Confirm",

  ADD_STORY_LIKE = "Story Like Add",
  REMOVE_STORY_LIKE = "Story Like Remove",

  PLAY_STORY = "Story Play",
  VIEW_SIGN_UP_OR_LOGIN_WALL = "Sign Up Or Log In Wall View",

  VIEW_LIST_OF_TOURS = "Tour List View",
  VIEW_TOUR_PREVIEW = "Tour Preview View",
  VIEW_TOUR_OVERVIEW_TAB = "Tour Overview Tab View",
  VIEW_TOUR_STOP_LIST_TAB = "Tour Tour Stop List Tab View",
  VIEW_TOUR_MAP_TAB = "Tour Map Tab View",
  VIEW_TOUR_START = "Tour Start View",
  START_PREVIEW_TOUR_STOP = "Preview Tour Stop Start",
  START_TOUR = "Tour Start",
  LIST_OF_TOURS_EXPLORE_MAP_BUTTON = "Explore Tours Map Button Clicking At Tour List",

  VIEW_TOURS_MAP = "Tours Map View",
  TOURS_MAP_SLIDER_SCROLLED = "Slider On Tours Map Scrolled",

  TOUR_CARD_CLICKING = "Tour Card Clicking",

  START_GROUP_SHARING = "Start Group Sharing",
  INVITE_TO_GROUP_SHARING = "Invite To Group Sharing",
  LEAVE_GROUP_SHARING = "Leave Group Sharing",

  VIEW_CREATOR_PROFILE = "Creator Profile View",

  VIEW_STORY_MAP = "Story Map View",
  STORY_MAP_SEARCH_IN_SELECTED_PLACE_BUTTON = "Search In Selected Place Button Clicking At Story Map",

  STORY_PLAYER_OPEN_SCRIPT_BUTTON = "Open Script Button Clicking At Story Player",
  STORY_PLAYER_CLOSE_TOUR_BUTTON = "Close Tour Button Clicking At Story Player",

  VIEW_USER_PROFILE = "User Profile View",
  VIEW_YOUR_ORDERS = "Your Orders View",
  VIEW_YOUR_ACCOUNT = "Your Account View",
  VIEW_VOUCHER_CODE = "Voucher Code View",
  VOUCHER_CODE_REDEEM = "Voucher Code Redeem",
  VIEW_YOUR_DOWNLOADS = "Your Downloads View",
  HELP_AND_SUPPORT = "Help And Support",
  CONTACT_US = "Contact Us",
  LOG_OUT = "Log Out",

  GO_TO_PURCHASING = "Go To Purchasing",

  VIEW_PREMIUM_ACCESS_PURCHASING = "Premium Access Purchasing View",
  BUY_PREMIUM_ACCESS = "Buy Premium Access",

  VIEW_VOICES = "Voices View",
  CHANGE_VOICE = "Change Voice",

  CHANGE_LANGUAGE = "Change Language",
  CITY_SEARCH_BAR_FOCUSED = "City Search Bar Focused",
  CITY_SEARCH_BAR_SELECT_CITY = "Select City In City Search Bar",
}

export enum MixpanelPeopleProperties {
  STORIES_VIEWED = "Stories Viewed",
  STORIES_LIKED = "Stories Liked",
}

// Needs to be rendered inside <AuthenticationProvider>
export const MixpanelUserIdenification: React.FC = () => {
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const user = useAuthStore(state => state.me);

  // Identify the user
  useEffect(() => {
    if (mixpanelEnabled && user) {
      // Identify user
      mixpanel.identify(user.id);

      // TODO: update user data
      // Set user properties
      mixpanel.people.set({
        $email: user.email,
        $name: user.email,
        $first_name: user.profile?.firstName,
        $last_name: user.profile?.lastName,
      });

      // Set default user properties only once if they're not set
      mixpanel.people.set_once({
        [MixpanelPeopleProperties.STORIES_VIEWED]: 0,
        [MixpanelPeopleProperties.STORIES_LIKED]: 0,
      });
    }
  }, [mixpanelEnabled, mixpanel, user]);

  return null;
};

const useValue = (mixpanelConfig?: Partial<MixpanelConfig>) => {
  const [mixpanelEnabled, setMixpanelEnabled] = useState(false);

  // Init maxpanel client
  useEffect(
    () => {
      const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

      if (mixpanelToken) {
        // Init mixpanel client
        mixpanel.init(mixpanelToken, mixpanelConfig);

        // Set mixpanelEnabled to true
        setMixpanelEnabled(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { mixpanel, mixpanelEnabled };
};

const MixpanelContext = createContext({} as ReturnType<typeof useValue>);

const MixpanelProvider: React.FC<{ config?: Partial<MixpanelConfig> }> = ({
  children,
  config: mixpanelConfig,
}) => {
  return (
    <MixpanelContext.Provider value={useValue(mixpanelConfig)}>
      {children}
    </MixpanelContext.Provider>
  );
};

const useMixpanel = () => {
  return useContext(MixpanelContext);
};

export { MixpanelProvider, useMixpanel };
