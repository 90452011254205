import { useCallback, useEffect, useState } from 'react';
import { find, uniqBy } from 'lodash-es';

import { useLocale } from '../contexts/LocaleContext';
import {
  useVoicesQuery,
} from '../graphql/dato/__generated__/dato-graphql.generated';
import { Voice } from '../interfaces/Interfaces';

const useVoices = (isVisible: boolean) => {
  const { queryLocale, locale } = useLocale();

  const [voicesInQueryLocale, setVoicesInQueryLocale] = useState<{ [key: string]: Voice[]; }>({});
  const [defaultVoiceInQueryLocale, setDefaultVoiceInQueryLocale] = useState<{ [key: string]: Voice; }>({});
  const [isAllVoicesReceivedInQueryLocale, setIsAllVoicesReceivedInQueryLocale] = useState<{ [key: string]: boolean; }>({});

  // Query for voices when rending the component
  const voicesPageSize = 100;
  const [voicesPageNumber, setVoicesPageNumber] = useState(0);

  const voicesQueryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      first: voicesPageSize,
      skip: voicesPageNumber * voicesPageSize,
    };
  }, [queryLocale, voicesPageNumber]);

  const [voicesResult] = useVoicesQuery({
    variables: voicesQueryVariables(),
    pause: !isVisible
  });

  const { data: voicesData } = voicesResult;

  // Set voices when data is available
  useEffect(() => {
    if (voicesData?.voices) {
      // Add voices to the list
      const returnedVoices = voicesData?.voices as Voice[];
      const newVoicesInQueryLocale = uniqBy(
        [...(voicesInQueryLocale?.[locale] || []), ...returnedVoices],
        (voice) => voice.id
      );
      setVoicesInQueryLocale({
        ...voicesInQueryLocale,
        [locale]: newVoicesInQueryLocale,
      });

      // Fetch more voices if available
      if (returnedVoices.length === voicesPageSize) {
        // By setting the page size another GraphQL query for the next page gets executed
        setVoicesPageNumber(voicesPageNumber + 1);
      } else {
        setIsAllVoicesReceivedInQueryLocale({
          ...isAllVoicesReceivedInQueryLocale,
          [locale]: true,
        });
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [voicesData]
  );

  useEffect(() => {
    if (isAllVoicesReceivedInQueryLocale[locale]) {
      const defaultVoice = find(voicesInQueryLocale[locale], ['isDefault', true]);
      if (defaultVoice) {
        setDefaultVoiceInQueryLocale({
          ...defaultVoiceInQueryLocale,
          [locale]: defaultVoice,
        });
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [voicesInQueryLocale, isAllVoicesReceivedInQueryLocale, locale]
  );

  return {
    voices: voicesInQueryLocale[locale],
    defaultVoice: defaultVoiceInQueryLocale[locale],
    isAllVoicesReceived: isAllVoicesReceivedInQueryLocale[locale],
  }
};

export default useVoices;
